.headerContainer {
  padding: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.headerTitle {
  font-size: 28px;
  font-weight: 600;
}

.headerDetailBox {
  margin-bottom: 10px;
}

.portionsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px;
  text-align: center;

  :global(.kiosk-web) & {
    padding: 10px;
  }
}

.portionContainer {
  flex-basis: 50%;
  padding: 5px;
  max-width: 50%;
}
