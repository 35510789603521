.image {
  width: 100%;
  position: relative;
  object-fit: contain;
  z-index: 1;

  :global(.kiosk-web) & {
    top: 0;
  }

  @media screen {
    @media (max-width: 772px) {
      top: 0;
      position: fixed;
    }
  }
}
