.item {
  border-bottom: 1px solid rgba(200, 199, 204, 0.5);
  min-height: 44px;
}

.item:hover {
  background-color: #efeff4;
}

.itemText {
  padding-left: 11px;
  padding-top: 13px;
}
