.content {
  padding: 11px;
}
.alternative a {
  color: #007aff;
  outline: none;
  border: none;
  margin: 0px;
  padding: 0px;
  background: none;
  font-weight: 700;
  cursor: pointer;
}
.alternative a::before,
.alternative a::after {
  content: ' ';
}
