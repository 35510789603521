.image {
  cursor: pointer;
  max-width: 786px;
  max-height: 160px;
  object-fit: cover;

  @media screen and (max-width: 1140px) {
    width: 100%;
  }
}

.hidden {
  display: none;
}
