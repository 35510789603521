.addressListWrapper {
  padding: 0px 8px;
}

.addressListWrapperWithBorder {
  padding: 0px 8px;
  border-top: 0.6px solid #a3a3a3;
}

.addressStoredListWrapper {
  width: 360px;
}
