.currentLocationField {
  height: 44px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.06);
  position: relative;
  align-items: center;
  gap: 8px;
  display: grid;
  grid-template-columns: 30px 1fr 40px;
}
.currentLocationIcon {
  display: flex;
  align-items: center;
}
.currentLocationDisplayField {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  z-index: 2;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
.currentLocationClearButton {
  background-color: transparent;
  cursor: pointer;
}
.currentLocationUnderline {
  height: 0px;
  position: relative;
  top: -2px;
  border: 1px solid rgba(0, 0, 0, 0.54);
}
