.container {
  display: block;
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  left: 0;
  top: 0;
  border: 1px solid;
}
.checkmark {
  border: 2px solid;
  border-top: none;
  border-right: none;
  content: '';
  position: absolute;
  transform: rotate(-45deg);
}
.wrapper {
  align-items: center;
  display: flex;
}
.checkbox {
  padding-right: 10px;
}
.label {
  cursor: pointer;
}
