.deliveryAddressMapViewWrapper {
  display: flex;
  position: relative;
  width: 100%;
  height: 87vh;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  min-height: 584px;
  min-width: 360px;
}
.deliveryAddressComponentsWrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  position: absolute;
  height: 85vh;
  width: 100%;
  align-items: center;
  min-height: 584px;
  min-width: 360px;
  padding: 8px 0 8px 0;
  pointer-events: none;
}
.deliveryAddressPreviewWrapper {
  margin: 8px 0px 0px 0px;
  display: flex;
  justify-content: center;
  z-index: 2;
  pointer-events: auto;
}
.addressSearchContainer {
  flex: 0 0 auto;
  text-align: center;
  z-index: 2;
  pointer-events: auto;
}
.mapUseAbleAreaContainer {
  flex: 1 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  pointer-events: none;
}
.mapProvider {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.confirmAddressContainer,
.confirmAddressContainerMap,
.confirmAddressContainerList,
.confirmAddressContainerListError,
.confirmAddressContainerMapError {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 360px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5px 10px 0px;
  border-radius: 15px;
  background-color: white;
  pointer-events: auto;
}
.confirmAddressContainerMap {
  bottom: 8px;
  margin-top: 0px;
}
.confirmAddressContainerList {
  top: 8px;
}
.confirmAddressContainerListError {
  top: 126px;
}
.confirmAddressContainerMapError {
  bottom: 8px;
  margin-top: 0px;
}
