.contentWrapper {
  padding: 20px;
}

.row {
  margin-top: 5px;

  a,
  a:hover {
    color: #000 !important;
    font-weight: 600;
    font-size: 18px;
  }
}
