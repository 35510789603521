.content {
  border-radius: 15px;
  margin-top: 20px;
  background-color: white;
  box-shadow: 0px 0.5px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 10px 10px;
  margin-bottom: 10px;

  h2 {
    margin-top: 5px;
  }

  p {
    margin: 0 0 5px 0;
  }
}

.introText {
  padding: 0 7px 0 10px;
  margin-top: 0;
}

.noVouchers {
  padding: 10px 0;
  text-align: center;
  color: rgb(137, 137, 137);
}
