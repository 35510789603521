.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.main {
  padding: 0 0 80px 0;
}
@media screen and (max-width: 773px) {
  .main {
    padding: 0 0 30px 0;
  }
}
.main.noFooter {
  padding: 0 0 160px 0;
}
.main_map_view {
  padding: 0 0 0 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.main_map_view.noFooter {
  padding: 0 0 160px 0;
}
