.spotlightInstructionsContainer {
  height: calc(100vh - 40px);
  width: 33vw;
  display: flex;
  position: relative;
  justify-self: right;
  max-width: 400px;
}
.spotlightContent {
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 16px;
}
.iconContainer {
  display: flex;
  flex-grow: 0.6;
  align-items: end;
}
.lineContainer {
  height: calc(50% - 200px);
  width: calc(50% + 40px);
  position: absolute;
  top: 130px;
  left: 50%;
  display: flex;
}
.lineContainer .line {
  border-top: 2px solid white;
  border-left: 2px solid white;
  width: 100%;
}
.lineContainer .circle {
  border-radius: 50%;
  width: 7px;
  height: 7px;
  background-color: white;
  position: absolute;
  top: -3px;
  right: 0;
}
.serviceMethodAgain {
  width: 100%;
  font-size: 3rem;
  letter-spacing: -2px;
  text-transform: uppercase;
  line-height: 2.5rem;
  font-family: 'pizza_pressoutline';
  position: relative;
  white-space: pre-line;
  font-weight: bold;
}
.serviceMethodAgain::before {
  content: attr(data-servicemethodagain);
  font-family: 'pizza_pressreverse_shadow';
  position: absolute;
  top: 3px;
  left: 0px;
  white-space: pre-line;
  font-weight: bold;
}
.serviceMethodAgain::after {
  content: attr(data-servicemethodagain);
  font-family: 'pizza_pressfill';
  color: white !important;
  position: absolute;
  top: 0;
  left: 0;
  white-space: pre-line;
  font-weight: bold;
}
.pickUpAgain {
  color: #cf343d;
}
.deliveryAgain {
  color: #3478aa;
}
.serviceMethodExplanation {
  color: white;
  font-weight: 600;
  line-height: 1.5rem;
}
.doNotShowMeAgainContainer {
  padding: 15px;
  text-align: center;
  margin-top: 25px;
  flex-grow: 0.3;
}
.doNotShowMeAgainText {
  color: white;
  font-size: 1.1rem;
  font-weight: 700;
  cursor: pointer;
}
@media only screen and (min-width: 870px) and (max-width: 1150) {
  .lineContainer {
    width: calc((100vw - 65vw) / 3);
  }
}
@media only screen and (max-width: 869px) {
  .spotlightContent {
    justify-content: center;
  }
  .spotlightInstructionsContainer {
    justify-self: center;
    width: 85vw;
  }
  .lineContainer {
    width: 0;
    top: 140px !important;
  }
  .lineContainer .line {
    width: 2px;
  }
  .lineContainer .circle {
    top: -3px;
    left: -2px;
  }
  .doNotShowMeAgainContainer {
    flex-grow: 0;
  }
}
/* Safari 11-14.7 */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .spotlightContent {
      justify-content: baseline;
    }
    .iconContainer {
      align-items: flex-end;
    }
  }
}
/* Safari 11-13 */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .spotlightContent {
      gap: 0;
    }
    .serviceMethodAgain {
      margin-top: 16px;
    }
    .serviceMethodExplanation {
      margin: 16px 0px;
    }
    .doNotShowMeAgainContainer {
      margin-top: 20px;
    }
  }
}
