.baseEditorContent {
  margin-top: -40px;
}
@media screen and (max-width: 772px) {
  .baseEditorContent {
    margin-top: 60%;
  }
}
.legend {
  display: flex;
  justify-content: flex-end;
  margin-top: -34px;
}
@media screen and (max-width: 772px) {
  .legend {
    margin-top: -54px;
  }
}
