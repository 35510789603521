.errorInline {
  border: 3px solid #e21836;
  border-radius: 8px;
  padding: 15px;
  grid-gap: 15px;
  color: #e21836;
  font-weight: 500;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: max-content;
}
