.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.outerCircle {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  border: 2px solid #454545;
  padding: 2px;
}
:global(.kiosk-web) .outerCircle {
  width: 54px;
  height: 54px;
}
.outerCircle > svg {
  width: 26px;
  height: 26px;
}
:global(.kiosk-web) .outerCircle > svg {
  width: 46px;
  height: 46px;
}
.outerCircle > svg path {
  fill: #454545;
}
.selected > .outerCircle {
  border-color: #ff5c47;
}
.innerCircle {
  width: 26px;
  height: 26px;
  border-radius: 100%;
}
:global(.kiosk-web) .innerCircle {
  width: 46px;
  height: 46px;
}
.innerCross {
  position: relative;
}
.innerCross:before,
.innerCross:after {
  position: absolute;
  content: ' ';
  top: 5px;
  left: 12px;
  height: 15px;
  width: 3px;
  border-radius: 25px;
  background-color: #454545;
  opacity: 0.89999998;
}
:global(.kiosk-web) .innerCross:before,
:global(.kiosk-web) .innerCross:after {
  top: 10px;
  left: 21px;
  height: 25px;
  width: 5px;
}
.innerCross:before {
  transform: rotate(45deg);
}
.innerCross:after {
  transform: rotate(-45deg);
}
.name {
  text-align: center;
  min-height: 55px;
  color: #454545;
  font-weight: normal;
}
.name > span {
  display: block;
}
:global(.kiosk-web) .name {
  font-size: 25px;
}
.selected > .name {
  color: #ff5c47;
  font-weight: 600;
}
.price {
  font-size: 15px;
  text-align: center;
  line-height: 20px;
  font-weight: 600;
  margin: 0;
}
:global(.kiosk-web) .price {
  font-size: 25px;
}
