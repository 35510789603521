.titledCardContent {
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 15px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
}
