.wrapper {
  position: relative;
}
.points {
  display: grid;
  grid-auto-flow: column;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  justify-content: center;
  align-items: center;
}
