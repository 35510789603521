.title {
  margin-top: 0;
  padding: 4px 8px 4px 8px;
  font-size: 16pt;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.name {
  margin-top: 0;
  font-size: 14px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.subtitle {
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 300;
  margin: 0px;
}

.price {
  font-weight: 600;
}

.description {
  font-size: 14px;
  font-weight: 600;
  padding: 0px 8px 4px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.offerItem {
  display: flex;
  width: 215px;
  flex-direction: column;
}

.image {
  height: 234px;
  width: 100%;
  flex-shrink: 0;
}

.itemDetails {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 4px 0px;
  background-color: #efeff4;
}

.itemDetailsBody {
  width: 100%;
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  text-align: left;
  justify-content: center;
}

.itemsContainer {
  display: flex;
  padding: 0 0 2px 0;
}

@media screen and (max-width: 771px) {
  .content {
    width: 360px;
  }
  .itemsContainer {
    flex-direction: column;
    max-height: calc(100vh - 450px);
    overflow: auto;
  }
  .image {
    width: 90px;
    height: 90px;
  }
  .offerItem {
    width: 100%;
    flex-direction: unset;
  }
  .itemDetails {
    height: unset;
  }
}
