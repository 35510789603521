.virtualKeyboard {
  width: 90%;
  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.closeButton {
  max-width: 70px;
  background-image: url('../../../res/assets/svgs/hide-keyboard-icon.svg') !important;
  background-size: 70px 35px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.switchLayoutButton {
  max-width: 80px;

  & > span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    & > div {
      width: 30px;
      height: 30px;
      background-size: contain !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-image: url('../../../res/assets/svgs/change-language.svg') !important;
    }

    & > span {
      flex: 1;
      font-size: 0.7rem;
      text-align: center;
    }
  }
}
