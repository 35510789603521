.wrapperExpanded {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

.wrapperCollapsed {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.headerIcon {
  display: block;
  float: left;
  margin: 0 10px 0 0;
}

.chevron {
  position: absolute;
  top: 15px;
  right: 13px;
}

.section {
  box-shadow: 0px 2px 10px rgba(137, 137, 137, 0.257676);
  opacity: 1;
  margin: 0px 0px 8px;
  padding: 6px 10px;
  position: relative;
  display: grid;
  cursor: pointer;
  border-radius: 23px;
}

.sectionExpanded {
  padding: 6px 10px;
  cursor: default;
  border-top-left-radius: 23px;
  border-top-right-radius: 23px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
