.logInButton {
  cursor: pointer;
  color: #4183c4;
  font-weight: bold;
}

.constrainWidth {
  padding: 0 10px;
}

.description {
  font-size: 12px;
  padding: 0 10px;
  margin-bottom: 8px;

  p {
    padding: 0;
    margin-bottom: 8px;
  }
}

.inputHeader {
  padding: 0 10px 8px 10px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  color: #4a4a4a;
  letter-spacing: 0.25px;
}

.recaptchaPolicy {
  color: #4a4a4a;
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  padding: 0 10px;
  width: 100%;
}

.gdpr {
  font-size: 12px;
  font-weight: bold;
  color: #4a4a4a;
  margin: 0 0 10px 0;
  padding: 0 10px;
}

.gdpr p {
  margin-top: 0;
}

.gdpr a {
  color: #4183c4;
}
