.radioInputContainer {
  display: flex;
  margin-bottom: 8px;

  input {
    height: 20px;
    width: 20px;
    margin: 0;
    margin-right: 8px;
  }

  label {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
  }
}
