.actionButton {
  justify-content: center;
  min-height: 32px;
  width: 100%;
  font-size: 14px !important;
  font-weight: 600 !important;
}
:global(.kiosk-web) .actionButton {
  min-height: 64px;
  font-size: 28px !important;
}
.buttonContainer {
  justify-content: center;
}
.buttonContainer > button {
  width: 100%;
  min-height: 32px;
}
:global(.kiosk-web) .buttonContainer > button {
  min-height: 64px;
}
.buttonContainer > button > div {
  font-size: 14px !important;
  font-weight: 600 !important;
}
:global(.kiosk-web) .buttonContainer > button > div {
  font-size: 28px !important;
}
