.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 17px;
  white-space: nowrap;
  color: #ffffff;
  height: 100%;
  align-items: center;
  font-weight: 600;
}
:global(.kiosk-web) .container {
  font-size: 27px;
}
:disabled > .container {
  color: #4a4a4a;
}
.container > * {
  margin: 0px 7px;
}
.container > *:first-child {
  margin-left: 0;
  flex: 1;
  text-align: left;
}
.container > *:last-child {
  margin-right: 0px;
  flex: none;
}
.button {
  height: 54px;
  background-color: #1fc36a;
}
:global(.kiosk-web) .button {
  height: 80px;
}
.button.fill {
  width: 100%;
}
.button:disabled {
  background-color: #9b9b9b !important;
}
