.voucher {
  border-top: 1px solid rgba(137, 137, 137, 0.26);
  padding: 10px;
  display: flex;
}
.active {
  cursor: pointer;
  transition: background-color 180ms;
  background-color: transparent;
}
.active:hover {
  background-color: #efeff4;
}
.icon {
  flex-grow: 0;
  border-radius: 50%;
  background-color: #e21836;
  margin-right: 7px;
  height: 22px;
  width: 22px;
  min-width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon svg {
  position: relative;
  left: -1px;
}
.voucherText {
  display: flex;
  flex-direction: column;
}
.voucherText h3 {
  font-size: 15px;
  color: #4a4a4a;
  margin-bottom: 3px;
  margin-top: 0;
}
.voucherText p {
  color: #757575;
  font-size: 13px;
  line-height: 14px;
  margin: 0 0 2px 0;
}
.voucherText .expiry {
  color: #4a4a4a;
  margin: 0 0 4px 0px;
  font-weight: 500;
}
