@import '../../../hooks-and-hocs/theme/theme-data/standard-theme.less';

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  gap: 0.5rem;
  .container {
    height: 2.25rem !important;
    background: transparent !important;
    padding-left: 0 !important;
  }
  & input {
    border-radius: @borderRadius[web-medium] !important;
    width: 100%;
  }
}

.inputError {
  border-width: 1px !important;
  border-style: solid !important;
  color: @standardColors[error-default] !important;
  border-color: @standardColors[error-default] !important;
  background-color: @standardColors[error-background] !important;
  outline: none !important;
}

.errorContainer {
  padding-top: 0.125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.error {
  font-size: 0.875rem;
  text-transform: uppercase;
  padding: 0.125rem 0px;
  text-align: center;
  font-weight: 400;
}

.button {
  min-height: 2.25rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  background-color: @standardColors[action-text-alternative] !important;
  border-radius: @borderRadius[web-small] !important;
}
