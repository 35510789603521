@import '../../../../hooks-and-hocs/theme/theme-data/standard-theme.less';

.title {
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02;
  color: @standardColors[black];
  margin: 0;
  font-size: 22px;
  max-width: 220px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.subtitle {
  height: 19px;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0;
  color: @standardColors[grey];
  margin: 0;
}

.accountContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    width: 26px;
    height: 24px;
  }
}

.textContainer {
  padding-left: 10px;
}

@media only screen and (max-width: 772px) {
  .title {
    font-size: 16px;
    max-width: 120px;
  }

  .textContainer {
    padding-left: 6px;
  }

  .subtitle {
    display: none;
  }
}
