.section {
  display: grid;
  padding: 11px;
  grid-gap: 11px;
  place-items: center;
}
.nativeSection {
  display: grid;
  padding: 11px;
  grid-gap: 11px;
}
.alternative {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;
  padding: 11px;
  justify-content: center;
  text-align: center;
}
.alternative a {
  color: #007aff;
  outline: none;
  border: none;
  margin: 0px;
  padding: 0px;
  background: none;
  font-weight: 700;
  cursor: pointer;
}
