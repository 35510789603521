.payment-method {
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  padding: 15px;
  padding-left: 0px;
}
.payment-method > input[type='radio'] {
  height: 20px;
  width: 20px;
  margin: 0;
  margin-right: 12px;
  :global(.kiosk-web) & {
    height: 30px;
    width: 30px;
  }
}

.payment-method > label {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
  font-size: 22px;
  font-weight: 700;
  :global(.kiosk-web) & {
    font-size: 32px;
  }
}

.hidden {
  display: none;
}

.payment-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
