.headerTitle {
  font-size: 28px;
  font-weight: 600;
}
:global(.kiosk-web) .headerTitle {
  font-size: 56px;
  font-weight: 600;
}
.headerDetailBox {
  margin-bottom: 10px;
}
:global(.kiosk-web) .headerDetailBox {
  margin-top: 50px;
  margin-bottom: 20px;
}
.headerPrice {
  font-size: 15px;
}
:global(.kiosk-web) .headerPrice {
  font-size: 25px;
}
