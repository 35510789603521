.container {
  border-top: 1px solid #c8c7cc;
  padding: 10px 18px;
}
.body {
  margin-top: 10px;
}
.body > img {
  max-width: 100%;
  max-height: 140px;
}
img.halfWidth {
  max-width: 50%;
}
.body > p {
  margin: 10px 0;
}
.title {
  font-weight: bold;
}
