.summaryContainer {
  background-color: rgba(155, 155, 155, 0.09);
  padding: 10px;
  margin-top: 10px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.divider {
  height: 2px;
  width: 100%;
  margin: 6px 0px;
  background-color: rgba(155, 155, 155, 0.1);
}
