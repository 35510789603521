@bg-colour: #f6f6f6;
@active-colour: #007aff;
@inactive-colour: #4a4a4a;

/* Hide scrollbar for Chrome, Safari and Opera */
.tabs::-webkit-scrollbar {
  display: none;
}

.tabs {
  list-style: none;
  margin: 0;
  align-self: flex-end;
  display: flex;
  overflow-x: scroll;
  background-color: @bg-colour;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  zoom: 1;
  padding: 0;
  .leftNavMenu & {
    flex-direction: column;
    background-color: #fff;
  }
}

.tabs li {
  /* Makes a horizontal row */
  float: left;
  position: relative;
  font-weight: 600;
  font-size: 9px;
  padding: 10px 8px 8px 8px;
  text-align: center;
  border-bottom: 2.5px solid @bg-colour;
  min-width: 70px;
  max-width: 70px;
  display: flex;
  justify-content: center;
  :global(.kiosk-web) & {
    font-size: 20px;
    min-width: 11vw;
    max-width: 11vw;
    svg {
      height: 50px;
      width: 50px;
    }
  }
  .leftNavMenu & {
    min-width: 15vw;
    max-width: 15vw;
    min-height: 8vh;
    border-bottom: 1px solid #d5d3d3d3;
  }
}

.tabs a {
  /* Make them block level
     and only as wide as they need */
  float: left;
  text-decoration: none;

  color: @inactive-colour;
  display: flex;
  flex-direction: column;
  align-items: center;
  .leftNavMenu & {
    padding-top: 1vh;
  }
}

.tabs a:hover {
  color: @inactive-colour;
}
.tabs .active {
  /* Highest, active tab is on top */
  z-index: 3;

  .leftNavMenu & {
    border-left: 4.5px solid @active-colour;
  }
}

.tabs .active a {
  /* Colors when tab is active */
  color: @active-colour;
}
.tabs a .label {
  margin-top: 4px;
}

.desktopTopSection {
  display: flex;
  justify-content: center;
  background-color: @bg-colour;
  align-items: center;
  max-width: 100vw;
  overflow-x: hidden;

  > .empty {
    display: flex;
    flex: 1 1 10%;
    justify-content: flex-end;
    align-self: stretch;
    align-items: center;
  }
  &.leftNavMenu {
    max-width: 20vw;
    position: absolute;
    margin-top: 15vh;
    border-radius: 0 20px 20px 0;
  }
}
