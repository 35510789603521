.scale {
  border-bottom: 1px solid rgba(151, 151, 151, 1);
  padding: 0 8px 8px 8px;
  margin-bottom: 8px;
  text-align: center;

  p {
    font-weight: 600;
  }

  .validationError {
    color: red;
    margin-top: 0;
  }

  @media screen and (max-width: 772px) {
    text-align: left;
  }
}

.centre {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ticks {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  list-style: none;
  padding: 0;
  margin: 0 0 16px 0;
}

.tick {
  align-items: center;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  height: 68px;
  justify-content: center;
  padding: 0 10px;
  border-right: 1px solid #ffffff;
}

@media screen and (max-width: 357px) {
  .tick {
    padding: 0 8px;
  }
}

.inactive {
  background-color: rgba(151, 151, 151, 1);
}

.tick-0,
.tick-1,
.tick-2,
.tick-3,
.tick-4,
.tick-5,
.tick-6 {
  background-color: rgba(255, 92, 71, 1);

  &:hover {
    background-color: rgba(255, 92, 71, 0.9);
  }
}

.tick-7,
.tick-8 {
  background-color: rgba(255, 206, 0, 1);

  &:hover {
    background-color: rgba(255, 206, 0, 0.9);
  }
}

.tick-9,
.tick-10 {
  background-color: rgba(86, 200, 77, 1);

  &:hover {
    background-color: rgba(86, 200, 77, 0.9);
  }
}
