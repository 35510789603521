.cardContainer {
  width: 343px;
  box-shadow: 0px 0.5px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  background-color: white;
  z-index: 1;
  position: relative;
  overflow: hidden;
}
.cardHeader {
  margin: 17px;
  text-align: center;
}
.closeButton {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  top: 17px;
  right: 12px;
  position: absolute;
  cursor: pointer;
  background-color: #4a4a4a;
}
.loadingSpinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.contentContainer {
  max-width: 95vw;
  min-height: 250px;
  max-height: 90vh;
  padding: 5px 15px 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.notFoundMessage {
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(0, 122, 255, 0.2);
  color: #007aff;
}
.contentFooter {
  display: flex;
  gap: 5px;
}
.contentFooter > button {
  flex: 1;
}
