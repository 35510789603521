.container {
  display: flex;
  align-items: center;
  background-color: #eee;
  border-radius: 10px;
  padding: 10px;
}

.icon {
  margin-right: 10px;
}

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-weight: 600;

  .contentPrice {
    height: 17.5px;
  }

  .contentLabel,
  .contentPricePrefix {
    color: #4a4a4a;
  }

  .contentLabel {
    text-transform: uppercase;
    font-size: 12px;
  }

  .contentPricePrefix {
    margin-right: 0.5em;
  }
}

.remove {
  background-color: transparent;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  max-height: 36px;
  padding: 4px 10px;
  text-transform: uppercase;
  color: #e21836;
  margin-left: auto;

  &:disabled {
    cursor: not-allowed;
  }
}

.zeroBalance {
  color: #e21836;
}
