.cardContent {
  padding: 10px 10px 0 10px;
}
@media screen and (max-width: 365px) {
  .card {
    margin: 4px 2px;
  }
  @supports (display: grid) {
    .card {
      margin: 0px;
    }
  }
}
.imageContainer {
  position: relative;
}
.card {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 4px;
  width: 174px;
}
@supports (display: grid) {
  .card {
    margin: 0px;
  }
}
:global(.kiosk-web) .card {
  width: 322px;
}
:global(.kiosk-web) .card.leftNavMenu {
  width: 274px;
}
.image {
  width: 100%;
  cursor: pointer;
  object-fit: cover;
  height: 150px;
}
:global(.kiosk-web) .image {
  height: 264px;
}
:global(.kiosk-web) .leftNavMenu .image {
  height: 230px;
}
.viewButton {
  position: absolute;
  cursor: pointer;
  right: 8px;
  top: 11px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.4);
  font-weight: bold;
  color: #fff;
  border: none;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  height: 34px;
  font-size: 16px;
}
:global(.kiosk-web) .viewButton {
  min-width: 88px;
  height: 60px;
  font-size: 28px;
}
.subtitle {
  padding-top: 6px;
  font-weight: 300;
  margin: 0px;
  font-size: 14px;
}
:global(.kiosk-web) .subtitle {
  font-size: 25px;
}
.description {
  overflow-y: hidden;
  margin: 0;
  padding-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-height: 17px;
  font-size: 12px;
  max-height: 58px;
}
:global(.kiosk-web) .description {
  line-height: 30px;
  font-size: 21px;
  max-height: 102px;
}
.title {
  overflow-y: hidden;
  margin: 0;
  margin-top: 0;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 17px;
  font-size: 15px;
  max-height: 35px;
}
:global(.kiosk-web) .title {
  line-height: 30px;
  font-size: 26px;
  max-height: 62px;
}
