.container {
  position: relative;
  border-radius: 1rem;
  display: flex;
}
.container input {
  font-size: 1rem;
  height: 2.25rem;
  padding-left: 0.5rem;
  border-color: #979797;
  border-radius: 1rem;
  color: #000000;
  background: transparent;
}
.container svg g path {
  fill: #7f7f7f;
}
.iconContainer {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0.5rem;
}
