.accessory {
  display: grid;
  grid-auto-flow: column;
  align-items: baseline;
  justify-content: flex-end;
  border: none;
  cursor: pointer;
}
.accessory svg path {
  fill: #979797;
}
.accessory:hover svg path {
  fill: #4a4a4a;
}
:global(.kiosk-web) .accessory > svg {
  width: 31px;
  height: 31px;
}
