.container {
  background-color: rgba(0, 122, 175, 0.1);
  border-radius: 8px;
  padding: 6.5px;
}
.header {
  display: flex;
  margin-bottom: 10px;
}
.title {
  align-items: center;
  margin: 0;
  font-size: 20px;
}
:global(.kiosk-web) .title {
  font-size: 40px;
}
.learnMoreButton {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  top: 7px;
  right: 6px;
  cursor: pointer;
  margin-left: 6px;
}
:global(.kiosk-web) .learnMoreButton {
  width: 44px;
  height: 44px;
  margin-left: 9px;
}
.learnMoreButton > svg {
  width: 22px;
  height: 22px;
}
:global(.kiosk-web) .learnMoreButton > svg {
  width: 44px;
  height: 44px;
}
.body {
  display: flex;
  margin-bottom: 10px;
  align-items: flex-start;
}
.image {
  flex: 1;
  display: flex;
  align-items: center;
  align-self: center;
}
.image > img {
  width: 100%;
}
.description {
  flex: 2;
  display: flex;
  font-size: 12px;
  margin: 0 0 0 8px;
  color: #4a4a4a;
}
:global(.kiosk-web) .description {
  font-size: 24px;
}
.checkbox {
  display: flex;
  padding: 10px;
  overflow: hidden;
  border-radius: 6px;
  background-color: #017aaf;
  border: 1px solid #017aaf;
}
.checkbox > div {
  flex-direction: row-reverse;
  width: 100%;
}
.checkbox > div > div:first-child {
  padding-right: 0;
}
.checkbox > div > div:last-child {
  flex: 1;
}
.label {
  padding-right: 5px;
  font-size: 12px;
  font-weight: bold;
  color: #017aaf;
}
:global(.kiosk-web) .label {
  font-size: 24px;
}
.legalText {
  padding: 0 10px;
  font-size: 12px;
  font-weight: bold;
}
:global(.kiosk-web) .legalText {
  font-size: 24px;
}
