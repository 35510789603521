.wrapper {
  background-color: white;
  position: fixed;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content auto;
  top: 14vh;
  left: 50%;
  transform: translateX(-50%);
  max-width: 90vw;
  min-width: 260px;
  border-radius: 15px;
  box-shadow: 0 1px 5px 0 rgba(137, 137, 137, 0.26);
  padding: 0px;
}
@media screen and (max-width: 450px) {
  .wrapper {
    width: 100%;
  }
}
.icon {
  border-right: solid 0.5px rgba(151, 151, 151, 0.2);
  padding: 15px;
}
.content {
  padding: 15px;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 5px;
  align-items: center;
  justify-items: center;
}
.content:has(.message) {
  justify-items: start;
}
.heading {
  font-size: 1.5em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.12px;
  color: #000000;
}
.message {
  color: #000000;
}
.fadeOut {
  opacity: 0;
  transition: opacity 0.8s;
}
