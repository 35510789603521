.wrapper {
  display: flex;
  justify-content: center;
  margin: 8px 8px 40px;
}

.main {
  width: 100%;
  max-width: 360px;
}
