.container {
  display: flex;
  padding: 0px 15px;
  position: relative;
  min-height: 56px;
}

.main {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  padding: 13px 0px;
}

.prefixIcon {
  margin-right: 10px;
  fill: #454545;
}

.section {
  flex: 2;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.h4 {
  text-transform: uppercase;
  margin: 0px;
  font-size: 12px;
  color: #4a4a4a;
}

.h5 {
  font-weight: normal;
  margin: 0px;
  font-size: 16px;
  color: #4a4a4a;
}

.select {
  position: absolute;
  left: 0px;
  height: 100%;
  width: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
  appearance: none;
}

.placeholder {
  font-size: 16px;
  color: #4a4a4a;
}

.withEllipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026';
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1em;
  }
}
