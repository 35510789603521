.spotlightContainer {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.4);
}
@supports (display: grid) {
  .spotlightContainer {
    display: grid;
    grid-template-columns: [menu] minmax(358px, 915px) [gap] minmax(8px, 60px) [sidebar] 385px;
    grid-template-areas: 'menu . sidebar';
  }
}
@media only screen and (max-width: 869px) {
  @supports (display: grid) {
    .spotlightContainer {
      display: grid;
      grid-template-columns: [menu] minmax(358px, 915px);
      grid-template-areas: 'menu';
    }
  }
}
