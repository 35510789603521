.empty {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex: 1;
}
:global(.kiosk-web) .empty {
  display: none;
}
.container {
  background-color: white;
  display: flex;
  flex: 0 0 auto;
  position: sticky;
  top: 0;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
  box-shadow: 0 0.5px 0.125rem 0 rgba(0, 0, 0, 0.13);
  height: 88px;
  padding: 0 27px 0 18px;
}
:global(.kiosk-web) .container svg {
  width: 160px;
  height: 55px;
}
@media only screen and (max-width: 772px) {
  .container {
    height: 48px;
    padding: 0 8px 0 8px;
  }
}
