.title {
  margin-top: 0;
  font-size: 20px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.subtitle {
  font-size: 14px;
  font-weight: 300;
  margin: 0px;
}
.price {
  font-weight: 600;
}
.description {
  font-size: 14px;
  font-weight: 600;
  padding-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.content {
  padding: 0px 6px 4px 6px;
  width: 395px;
}
@media screen and (max-width: 430px) {
  .content {
    width: 360px;
  }
}
