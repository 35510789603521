@import '../../../hooks-and-hocs/theme/theme-data/standard-theme.less';

.svgFill(@color) {
  & svg {
    & g {
      & path {
        fill: @standardColors[input-icon-color];
      }
    }
  }
}

.container {
  position: relative;
  border-radius: 1rem;
  display: flex;
  & input {
    font-size: 1rem;
    height: 2.25rem;
    padding-left: 0.5rem;
    border-color: @standardColors[light-slate];
    border-radius: @borderRadius[web-standard];
    color: @standardColors[black];
    background: transparent;
  }
  .svgFill(@standardColors[input-icon-color]);
}

.iconContainer {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0.5rem;
}
