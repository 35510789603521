.modalContainer {
  height: 100%;
  width: 100%;
  padding: 0;
  overflow-y: hidden;
  position: absolute;
  top: 0;
}
.containerView {
  flex-direction: column;
  margin-top: 14vh;
  overflow: hidden;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 86vh;
  background-color: white;
  width: 100%;
}
.listContainer {
  overflow-y: auto;
  height: 80vh;
}
.header {
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  position: sticky;
  height: 6vh;
}
.headerTitle {
  font-size: 28px;
  font-weight: 600;
}
.sectionHeader {
  color: #f8f8f8;
  background-color: #f8f8f8;
  padding: 10px 10px;
  margin: 0px;
}
.selectContainer {
  width: 100%;
}
.backButtonContainer {
  align-items: center;
  color: #007aff;
  display: flex;
  cursor: pointer;
}
.headerText {
  font-size: 16px;
  font-weight: 600;
  padding: 0rem 1.5rem;
}
.backButtonText {
  font-size: 16px;
  font-weight: 500;
  padding: 0rem 0.5rem;
  align-self: center;
}
.sectionTextContainer {
  display: flex;
  background-color: white;
  padding: 0rem 0.7rem;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  justify-content: space-between;
}
.sectionTextContainer p {
  cursor: pointer;
}
