.wrapper {
  cursor: pointer;
}
.container,
.narrowContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 11px 19px 0;
  border-top: 0.6px solid #a3a3a3;
}
.narrowContainer {
  padding: 14px 11px 14px 0;
}
.removeBorderOnFirstItem .wrapper:first-child .container,
.removeBorderOnFirstItem .wrapper:first-child .narrowContainer {
  border-top: 0;
}
.addBorderOnLastItem .wrapper:last-child .container,
.addBorderOnLastItem .wrapper:last-child .narrowContainer {
  border-bottom: 0.6px solid #a3a3a3;
}
.textContainer {
  flex: 1 2 auto;
  margin-right: 8px;
}
.title,
.highlightTitle {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.36px;
  margin: 0;
}
.highlightTitle {
  color: #007aff;
}
.subtitle {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.2px;
  margin: 0;
}
.storeDetailsDistance {
  font-size: 16px;
  font-weight: 600;
  text-align: right;
  margin: 0;
  white-space: nowrap;
}
.iconContainer {
  padding-right: 8px;
}
.suffix {
  display: flex;
  align-items: center;
}
