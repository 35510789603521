@import '../../../../hooks-and-hocs/theme/theme-data/standard-theme.less';

.cardWrapper {
  flex: 1;
  flex-direction: column;
  border-radius: 15px;
  background-color: #dddddd;
  position: relative;
  :global(.kiosk-web) & {
    border-radius: 30px;
  }
  &.selected {
    background-color: #ffffff;
  }
}

.actionButtonWrapper {
  padding: 0 7.5px 8.3px 7.5px;
  :global(.kiosk-web) & {
    padding: 0 15px 16.6px 15px;
  }
}

.iconWrapper {
  text-align: center;
  padding: 28px 0;
  :global(.kiosk-web) & {
    padding: 56px 0;
  }
}

.image {
  width: 100%;
  height: 149px;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.resetButton {
  position: absolute;
  cursor: pointer;
  right: 8px;
  top: 11px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.4);
  font-weight: bold;
  color: #fff;
  border: none;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  line-height: 34px;
  font-size: 16px;
}

.openProductListButtonWrapper {
  padding: 0 7.5px 5px 7.5px;
  :global(.kiosk-web) & {
    padding: 0 15px 11px 15px;
  }
}

.openProductListButton {
  width: 100%;
  min-height: 30px;
  margin-top: 4px;
  background-color: @standardColors[action-background-green-light] !important;

  div {
    color: @standardColors[action-text-green] !important;
    font-weight: bold !important;
    font-size: 14px !important;
  }

  :global(.kiosk-web) & {
    height: 2rem;

    div {
      font-size: 27px !important;
    }
  }
}

.openProductListButton:disabled {
  background-color: @standardColors[button-background-disabled-light] !important;

  div {
    color: @standardColors[button-text-disabled-light] !important;
  }
}

.selectedPortionText {
  text-align: left;
  margin-left: 7.5px;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :global(.kiosk-web) & {
    font-size: 27px;
    margin-left: 15.5px;
  }
}
