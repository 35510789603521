@text-color: rgba(0, 0, 0, 0.54);
@font-size: 13px;

.streetInfo {
  color: @text-color;
  cursor: pointer;
  font-size: @font-size;
}

.additionalAddressInfoLabel {
  color: @text-color;
  font-size: @font-size;
}
