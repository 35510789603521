.wrapper {
  cursor: pointer;
}
.removeBorderAboveTitle {
  border-top: none !important;
  padding-top: 10px !important;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 14px 15px 14px 15px;
  border-top: 0.6px solid rgba(200, 199, 204, 0.5);
}
.listContainer {
  margin-left: -8px;
  margin-right: -8px;
}
.removeBorderOnFirstItem .wrapper:first-child .container {
  border-top: 0;
}
.removeBorderAfterListTitle .wrapper:nth-child(2) .container {
  border-top: 0;
}
.addBorderOnLastItem .wrapper:last-child .container {
  border-bottom: 0.6px solid rgba(200, 199, 204, 0.5);
}
.textContainer {
  flex: 1 2 auto;
}
.title,
.highlightTitle,
.subtitle,
.listTitle {
  opacity: 1;
  font-size: 15px;
  margin: 0;
  letter-spacing: 0.22px;
  text-align: left;
  text-wrap: wrap;
  overflow-wrap: break-word;
  word-break: break-word;
}
.highlightTitle {
  font-weight: 600;
  margin-bottom: 3px;
}
.subtitle {
  font-weight: 400;
}
.listTitle {
  font-weight: 600;
  letter-spacing: 0.25px;
}
.listTitleContainer {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 6px;
  padding-top: 14px;
  border-top: 0.6px solid rgba(200, 199, 204, 0.5);
}
.storeDetailsDistance {
  font-size: 16px;
  font-weight: 600;
  text-align: right;
  margin: 0;
  white-space: nowrap;
}
.iconContainer {
  margin-right: 13px;
  margin-top: 2px;
}
.suffix {
  display: flex;
  justify-content: center;
  align-items: center;
}
.suffixContainer {
  margin-left: 9px;
  justify-content: center;
  align-self: center;
}
.line-skeleton {
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
}
