.container {
  display: flex;
  align-items: center;
  padding: 0.15625rem 0.35rem;
  border: 0.03125rem solid #545456;
  border-radius: 0.3rem;
  color: white;
  width: 5.9375rem;
  position: relative;
  background-color: rgba(20, 15, 15, 0.7);
  height: 1.5625rem;
}
.container.selected {
  background-color: #414141;
}
.tick {
  position: absolute;
  right: 0.35rem;
  color: white;
}
.flag {
  width: 1.25rem;
  height: 0.75rem;
  margin-right: 0.25rem;
}
