.container {
  padding: 15px;
  border: 0;
  cursor: pointer;
  line-height: 0;
  min-height: 40px;
  border-radius: 0.5rem;
}
.text {
  color: white;
  font-weight: 600;
  min-width: 2.125rem;
}
