.buttonLabel {
  color: #ffffff;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  cursor: inherit;
}
.facebookicon {
  position: absolute;
  padding-top: 3px;
  left: 20px;
}
.errorMessage {
  color: #e21836;
  font-size: 14px;
  font-weight: 600;
}
