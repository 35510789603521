@import '../../../hooks-and-hocs/theme/theme-data/standard-theme.less';

.container {
  padding: 15px;
  border: 0;
  cursor: pointer;
  line-height: 0;
  min-height: 40px;
  border-radius: @borderRadius[web-small];
}

.text {
  color: white;
  font-weight: 600;
  min-width: 2.125rem;
}
