.storeContainer {
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex: 1 2 auto;
  height: 47px;
  padding-left: 14px;

  input[type='radio'] {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  label {
    margin-left: 8px;
    cursor: pointer;
    font-size: 14px;
    color: #000000;
  }

  p {
    margin-right: 8px;
  }

  .textContainer {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 2 auto;
    margin-right: 8px;
  }

  &:first-child {
    background-color: #e3e2e9;
  }

  &:first-child .textContainer {
    font-weight: 600;
  }
}
