.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.tooltipContainer {
  position: absolute;
  top: 50px;
  right: 10%;
}
