.main {
  padding: 12px;
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #c8c7cc;
}

.main:last-child {
  border-bottom: none;
}

.container {
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.details {
  flex: 1;
}

.quantity {
  font-size: 15px;
  font-weight: 600;
  margin-right: 6px;
  :global(.kiosk-web) & {
    font-size: 25px;
  }
}
