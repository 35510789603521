.wrapper {
  background-color: rgb(255, 255, 255);
  width: 100%;
  padding: 34px 8px 80px;
  margin-top: auto;

  @media screen and (min-width: 773px) {
    padding: 33px 8px 15px;
    :global(.kiosk-web) & {
      padding: 34px 8px 80px;
    }
  }

  hr {
    width: 50px;
  }

  a,
  p,
  #ot-sdk-btn {
    display: block;
    border: none !important;
    font-size: 15px !important;
    line-height: 20px !important;
    letter-spacing: -0.16px;
    margin: 0;
    padding: 0 !important;
  }

  a,
  a:hover,
  p,
  #ot-sdk-btn {
    color: #454545 !important;
    background-color: transparent !important;
  }
}

.terms {
  padding-bottom: 5.4px;
  max-width: 1137px;
  margin: auto;

  :global(.kiosk-web) & {
    max-width: unset;
  }

  p,
  b {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.12px;
    color: #454545;

    :global(.kiosk-web) & {
      font-size: 18px;
      text-align: justify;
      line-height: unset;
      letter-spacing: unset;
      color: unset;
    }
  }

  b {
    font-weight: bold;
  }
}

.legal {
  padding-top: 25px;
  text-align: center;

  @media screen and (min-width: 773px) {
    padding-top: 11px;
  }
}

.kJ {
  text-align: center;
  padding-bottom: 11.4px;
  max-width: 1137px;
  margin: auto;

  p {
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.24px;
    color: #000;
    margin: 0;
  }
}

.divider {
  display: block;
  max-width: 375px;
  height: 2px;
  background-color: #dfdfdf;
  margin: 16.4px auto;
}

.topContainer {
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.innerContainer {
  display: flex;
}

.nutrition {
  display: flex;
  margin-top: 20px;
}

.storeHealth {
  display: flex;
  margin-top: 20px;
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.desktopRow {
  display: flex;
  flex-direction: row;
}
