.link {
  color: #000000;
  outline: none;
  border: none;
  background: none;
  font-weight: 600;
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  :global(.kiosk-web) & {
    font-size: 25px;
  }
}
