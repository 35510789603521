.tipToggles {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(100px, auto));
  margin-bottom: 8px;
}

.tipToggle {
  color: #ffffff;
  font-size: 17px;
  font-weight: 700;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  line-height: 0;
  min-height: 30px;
  background-color: #9b9b9b;

  &.selected {
    color: #ffffff;
    background-color: #007aff;
  }
}

.tipTheDriverDisclaimer {
  color: #494a4b;
  font-size: 10px;
  margin: 0;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #c8c7cc;
}
