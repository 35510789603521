.titledCardContent {
  margin-left: 10px;
  margin-right: 10px;
}
.orderButtonContainer {
  margin-top: 10px;
}
:global(.kiosk-web) .orderButtonContainer {
  margin-top: 20px;
}
.subTitledImage {
  font-size: 22px;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
  position: absolute;
  color: white;
  font-family: 'pizza_pressfill';
  padding-top: 10px;
}
.titledImage {
  font-size: 44px;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
  position: absolute;
  color: white;
  font-family: 'pizza_pressfill';
  padding-top: 25px;
}
.imageContainer {
  text-align: center;
  width: '100%';
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.image {
  width: 100%;
  height: auto;
}
.streamlinedCheckoutDetails {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.15);
  padding: 8px 15px 8px 15px;
}
.streamlinedCheckoutDetails > div > h2 {
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  margin: 8px 8px 8px 0px;
}
