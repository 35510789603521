.section {
  box-shadow: rgba(0, 0, 0, 0.15) 0 0.5px 10px 0;
  margin: 0 0 8px;
  border-radius: 20px;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  padding: 15px;
}
.h3 {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  font-size: 22px;
  font-weight: 600;
}
.content {
  margin: 0;
  padding: 0 10px 10px 10px;
}
.partial-payment {
  padding: 15px 0 0 0;
}
.details {
  padding: 15px 0 0 5px;
}
.exceeds-message {
  padding: 0 5px 0 5px;
}
