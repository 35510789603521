@boxed-message--text--primary: #ff4b39;
@boxed-message--text--secondary: #000000;
@boxed-message--text--tertiary: #ffffff;
@boxed-message--text--information: #007aff;

@boxed-message--background--primary: #ffdeda;
@boxed-message--background--secondary: #e5e5e5;
@boxed-message--background--tertiary: #007aff;
@boxed-message--background--information: #c5ddf8;

@boxed-message--text--size--regular: 14px;
@boxed-message--text--size--small: 12px;
@boxed-message--text--size--large: 16px;

.messageContainer {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 10px;
  gap: 10px;

  > div {
    font-size: 12px;
    font-weight: 600;
  }
}

/* Variant */
.contained:extend(.messageContainer all) {
  border-color: transparent !important;
}

.outlined:extend(.messageContainer all) {
  border-width: 2px;
  border-style: solid;
  background-color: transparent !important;
}

/* Text variant */
.regular {
  > div {
    font-size: @boxed-message--text--size--regular !important;
  }
}
.small {
  > div {
    font-size: @boxed-message--text--size--small !important;
  }
}
.large {
  > div {
    font-size: @boxed-message--text--size--large !important;
  }
}

/* Color */
.primary {
  border-color: @boxed-message--text--primary;
  background-color: @boxed-message--background--primary;
  > div {
    color: @boxed-message--text--primary;
  }
}

.secondary {
  border-color: @boxed-message--text--secondary;
  background-color: @boxed-message--background--secondary;
  > div {
    color: @boxed-message--text--secondary;
  }
}

.tertiary {
  border-color: @boxed-message--text--tertiary;
  background-color: @boxed-message--background--tertiary;
  > div {
    color: @boxed-message--text--tertiary;
  }
}

.information {
  border-color: @boxed-message--text--information;
  background-color: @boxed-message--background--information;
  > div {
    color: @boxed-message--text--information;
  }
}

/* Icon color */
.icon-primary {
  g {
    fill: @boxed-message--text--primary;
  }
}

.icon-secondary {
  g {
    fill: @boxed-message--text--secondary;
  }
}

.icon-tertiary {
  g {
    fill: @boxed-message--text--tertiary;
  }
}

.icon-information {
  g {
    fill: @boxed-message--text--information;
  }
}
