.wrapper {
  position: relative;
}
.container {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 1.5rem;
  position: relative;
  outline: 0;
  border-radius: 0.375rem;
  border: solid 1px #cccccc;
  background-color: #ededed;
  text-align: left;
}
.label {
  align-items: center;
  flex: 1 1 0%;
  padding: 2px 6px;
  overflow: hidden;
  color: #333333;
  font-size: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 1.875rem;
}
.rightContainer {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
}
.seperator {
  background-color: #cccccc;
  width: 1px;
  align-self: stretch;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}
.icon {
  display: flex;
  padding: 0px 0.5rem;
}
.icon svg {
  height: 0.625rem;
  width: 0.625rem;
}
.selectBox {
  position: absolute;
  left: 10px;
  top: 3px;
  height: 100%;
  width: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
  appearance: none;
}
.disabled {
  opacity: 0.6;
}
