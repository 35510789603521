.terms {
  font-size: 12px;
  font-weight: bold;
  color: #4a4a4a;
  margin: 0 0 10px 0;
  padding: 0 10px;
}

.terms p {
  margin-top: 0;
}

.terms a {
  color: #4183c4;
}
