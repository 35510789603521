.orderFeedbackByLine {
  margin-top: 0;
  padding: 0 8px 0 8px;
}
.buttonWrapper {
  text-align: center;
}
.completed {
  display: flex;
  flex-direction: row;
  padding: 16px;
  gap: 16px;
}
.completed .message {
  text-align: left;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
}
.completed .message h3,
.completed .message p {
  flex-grow: 100%;
  margin: 0px;
}
@media screen and (min-width: 480px) {
  .completed .message {
    gap: 6px;
  }
}
