.image {
  display: block;
  height: 390px;
  width: 100%;
}
.image img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
