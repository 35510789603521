.content {
  display: grid;
  grid-auto-flow: row;
}
.balance {
  padding: 12px;
  font-size: 12px;
  display: grid;
  grid-auto-flow: row;
  justify-items: center;
  text-align: center;
  grid-gap: 6px;
}
.balance .remaining {
  font-size: 13px;
  font-weight: 800;
  color: #8798ad;
  text-transform: uppercase;
}
.balance .remaining .points {
  color: #007aaf;
}
.balance .remaining .points::after {
  content: ' ';
  display: inline;
}
.balance .pending {
  font-weight: 700;
}
.balance .disclaimer {
  padding: 0px 12px;
}
.redeem {
  padding: 10px;
  padding-bottom: 2px;
  display: grid;
  grid-gap: 12px;
  grid-auto-flow: column;
  grid-template-columns: min-content repeat(2, auto);
  align-items: center;
  border-top: 1px solid rgba(200, 199, 204, 0.5);
}
.redeem .label {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}
