.storeContainer {
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 2 auto;
  height: 47px;
  padding-left: 14px;
}
.storeContainer input[type='radio'] {
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.storeContainer label {
  margin-left: 8px;
  cursor: pointer;
  font-size: 14px;
  color: #000000;
}
.storeContainer p {
  margin-right: 8px;
}
.storeContainer .textContainer {
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 2 auto;
  margin-right: 8px;
}
.storeContainer:first-child {
  background-color: #e3e2e9;
}
.storeContainer:first-child .textContainer {
  font-weight: 600;
}
