.productEditorContent {
  padding: 8px;
  width: 100%;
  z-index: 2;
  padding-bottom: 178px;
}
.productEditorContent section {
  position: relative;
  margin-bottom: 8px;
}
:global(.kiosk-web) .productEditorContent {
  margin-bottom: 100px;
  padding-bottom: 25px;
}
@media screen and (max-width: 954px) {
  .productEditorContent {
    top: 203px;
  }
}
@media screen and (max-width: 772px) {
  .productEditorContent {
    margin-bottom: 65px;
    padding-bottom: 15px;
  }
}
