.voucher {
  border-top: 1px solid rgba(137, 137, 137, 0.26);
  padding: 10px;
  display: flex;
}

.active {
  cursor: pointer;
  transition: background-color 180ms;
  background-color: transparent;

  &:hover {
    background-color: #efeff4;
  }
}

.icon {
  flex-grow: 0;
  border-radius: 50%;
  background-color: rgb(226, 24, 54);
  margin-right: 7px;
  height: 22px;
  width: 22px;
  min-width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    position: relative;
    left: -1px;
  }
}

.voucherText {
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 15px;
    color: rgb(74, 74, 74);
    margin-bottom: 3px;
    margin-top: 0;
  }

  p {
    color: mix(black, white, 54%);
    font-size: 13px;
    line-height: 14px;
    margin: 0 0 2px 0;
  }

  .expiry {
    color: rgb(74, 74, 74);
    margin: 0 0 4px 0px;
    font-weight: 500;
  }
}
