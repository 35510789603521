.prompt {
  min-height: 50px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  text-align: center;
}
:global(.kiosk-web) .prompt {
  font-size: 20px;
}
.orderDetails {
  border-top: 1px solid #c8c7cc;
}
.orderDetails > div {
  padding: 10px;
  border-bottom: 1px solid #c8c7cc;
}
.orderDetails > div:nth-last-child(2),
.orderDetails > div:last-child {
  border: none;
}
.orderDetails > div:last-child {
  padding-top: 0;
}
.stickyButtonContainer {
  position: sticky;
  bottom: 7px;
  background-color: #ffffff;
  border-radius: 20px;
}
.stickyButtonContainer > div {
  border-bottom: 1px solid #c8c7cc;
  padding: 10px 0;
}
.stickyButtonContainer > div:first-child {
  padding-top: 0;
}
.stickyButtonContainer > div:last-child {
  border: none;
  padding-bottom: 0;
}
