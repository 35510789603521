.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.name {
  font-size: 15px;
  text-align: center;
  line-height: 15px;
  margin: 0;
  :global(.kiosk-web) & {
    font-size: 25px;
    line-height: 25px;
  }
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.icon {
  height: 100%;
  width: 100%;
}
