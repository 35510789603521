.compactWrapper .compactTitle {
  font-size: 15px;
  font-weight: 600;
}
.compactWrapper .options > .option {
  font-size: 14px;
  padding: 0;
}
.compactWrapper .options > .option:not(:first-of-type) {
  border: none;
  grid-auto-flow: unset;
}
.compactWrapper .options > .option:not(:first-of-type) > p {
  margin: 5px 0;
  font-size: 11px;
  font-weight: 600;
}
.options {
  display: grid;
  border: 0;
  outline: 0;
  padding: 0;
}
.options > .option {
  display: grid;
  padding: 10px 0;
  grid-auto-flow: column;
}
.options > .option:first-of-type {
  padding-top: 0;
}
.options > .option:not(:first-of-type) {
  border-top: 1px solid #e3e3e5;
}
:global(.kiosk-web) .options > .option input {
  width: 30px;
  height: 30px;
}
:global(.kiosk-web) .options > .option label {
  font-size: 25px;
}
