@import '../../../hooks-and-hocs/utils/breakpoints/breakpoints.less';

.storeTradeDetailsContainer {
  display: grid;
  grid-template-columns: min-content auto auto;
  column-gap: 8px;
  row-gap: 10px;
  font-size: 15px;
  margin-bottom: 10px;

  & .storeTradeDetailsName {
    font-weight: bold;
  }

  & .storeTradeDetailsValue {
    color: #4a4a4a;
    font-size: 14px;
  }
}

@media @mobile {
  .storeTradeDetailsContainer {
    grid-template-columns: min-content auto;
    row-gap: 4px;

    & .storeTradeDetailsValue {
      grid-column: 2;
    }

    & .storeTradeDetailsValue:has(+ svg) {
      margin-bottom: 4px;
    }
  }
}
