@field-height: 65px;
@focus-color: #2979ff;
@text-padding: 11px;

.wrapper {
  display: inline-block;
  vertical-align: top;
}

.wrapperDisabled {
  opacity: 0.5;
}

.textareaContainer {
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  height: @field-height;
  width: auto;
  position: relative;
}

.prefix {
  margin-top: 21px;
}

.placeholder {
  color: rgba(0, 0, 0, 0.54);
  left: @text-padding;
  position: absolute;
  top: @text-padding;
  transition: top 0.1s;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
}

.placeholderHasFocus:extend(.placeholderHasValue) {
  color: @focus-color;
  display: none;
}

.textarea {
  background-color: transparent;
  border: none;
  font-size: 15px;
  outline: none;
  padding-bottom: 7px;
  width: 100%;
  z-index: 2;
  height: 97%;
  resize: none;
  padding-left: 4px;
}

.textareaHasFocus:extend(.textarea) {
  caret-color: @focus-color;
}

.underline {
  border: 1px solid rgba(0, 0, 0, 0.54);
  height: 0px;
  position: relative;
  top: -2px;
  width: 100%;
}

.underlineHasFocus:extend(.underline) {
  border-color: @focus-color;
}

.spinner {
  margin-bottom: auto;
  margin-top: auto;
}
