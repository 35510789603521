.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: 1px solid #c8c7cc;
  border-bottom: 1px solid #c8c7cc;
  padding: 10px;
}
.header > * {
  width: 100%;
  flex: 1;
  margin: 0 5px;
}
.header > *:first-child {
  margin-top: 0;
  margin-left: 0;
}
.header > *:last-child {
  margin-bottom: 0;
  margin-right: 0;
}
@media screen and (min-width: 0px) and (max-width: 954px) {
  .header {
    flex-direction: column;
  }
  .header > * {
    margin: 4px 0;
  }
}
.list {
  list-style-type: none;
  padding-inline-start: 0;
  margin: 0;
}
.list > li {
  border-bottom: 1px solid #c8c7cc;
}
.list > li.coupon {
  padding: 10px;
}
.total {
  padding: 10px 18px;
}
.detailedSummaryContainer {
  padding: 10px 10px;
}
@keyframes placeholderShimmer {
  0% {
    background-position: -1600px 0;
  }
  100% {
    background-position: 1600px 0;
  }
}
.placeholderPicker {
  border-radius: 8px;
  height: 56px;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #efeff4 8%, #d6d6db 18%, #efeff4 33%);
  background-size: 1600px 64px;
}
.placeholderProducts {
  padding: 10px;
  border-bottom: 1px solid #c8c7cc;
}
.placeholderProduct {
  display: flex;
  justify-content: space-between;
}
.placeholderProductDetails {
  width: 60%;
}
.placeholderProductName,
.placeholderProductCustomisations,
.placeholderProductPrice {
  border-radius: 4px;
  height: 18px;
  margin-bottom: 4px;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #efeff4 8%, #d6d6db 18%, #efeff4 33%);
  background-size: 1600px 32px;
}
.placeholderProductName {
  width: 100%;
}
.placeholderProductCustomisations {
  width: 80%;
}
.placeholderProductPrice {
  width: 50px;
}
.placeholderTotal {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.placeholderTotal > div {
  border-radius: 4px;
  height: 18px;
  width: 50px;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #efeff4 8%, #d6d6db 18%, #efeff4 33%);
  background-size: 1600px 32px;
}
