.title,
.offerItem {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  color: #4a4a4a;
}

.title {
  padding-bottom: 4px;
}

.background {
  color: #e8eaed;
}

.container {
  display: block;
  padding: 8px 8px 4px 8px;
  border-radius: 10px;
  background-color: rgb(239, 239, 244);
}

.itemsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
