.section {
  box-shadow: rgba(0, 0, 0, 0.15) 0 0.5px 10px 0px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  padding: 15px;
  padding-left: 11px;
}

.h3 {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
  font-size: 22px;
  font-weight: 600;
  :global(.kiosk-web) & {
    font-size: 32px;
  }
}

.suffix {
  font-weight: 300;
  margin: 0px;
}

.chevron {
  margin-left: 15px;
}

.content {
  margin: 0px;
  padding: 0 8px 8px 8px;
}
