.wrapper {
  display: flex;
  margin: 8px 0 7px;
  gap: 7px;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-size: 15px;
    margin: 0;
    font-weight: 700;
  }

  .subtitle {
    font-size: 15px;
    margin: 0;
    font-weight: 400;
  }
}
