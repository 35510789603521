.animationView {
  z-index: 6;
  position: fixed;
  bottom: 10vh;
}
@media screen and (max-width: 772px) {
  .animationView {
    margin-inline: 16px;
    left: 0px;
    right: 0px;
  }
}
@media screen and (min-width: 773px) {
  .animationView {
    width: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 773px) and (max-width: 1139px) {
  .animationView {
    width: 500px;
  }
}
@media screen and (min-width: 773px) and (max-width: 954px) {
  .animationView {
    width: 350px;
  }
}
.confirmationContent {
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  padding: 8px;
  background-color: #e5f1ff;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0px 20px 10px;
  border-radius: 0.625rem;
}
@media screen and (min-width: 773px) {
  .confirmationContent {
    width: 375px;
  }
}
.imgColumn {
  width: 72px;
  display: grid;
  justify-content: center;
  align-items: center;
}
.textColumn {
  flex: 1;
  padding: 12px 0px 12px 8px;
}
.confirmationTitle {
  font-size: 18px;
  font-weight: 600;
}
.confirmationDescription {
  font-weight: 400;
  font-size: 18px;
}
