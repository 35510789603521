.wrapper {
  display: grid;
  grid-gap: 10px;
}

.image {
  padding: 40px;
  display: grid;
  justify-content: center;
}

.dismiss {
  width: 22px;
  height: 22px;
  padding: 1px;
  border-radius: 50%;
  top: 8px;
  right: 8px;
  z-index: 1;
  position: absolute;
  background-color: rgba(22, 22, 22, 0.7);
  cursor: pointer;
}
