.messageContainer,
.contained,
.outlined {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 10px;
  gap: 10px;
}
.messageContainer > div,
.contained > div,
.outlined > div {
  font-size: 12px;
  font-weight: 600;
}
/* Variant */
.contained {
  border-color: transparent !important;
}
.outlined {
  border-width: 2px;
  border-style: solid;
  background-color: transparent !important;
}
/* Text variant */
.regular > div {
  font-size: 14px !important;
}
.small > div {
  font-size: 12px !important;
}
.large > div {
  font-size: 16px !important;
}
/* Color */
.primary {
  border-color: #ff4b39;
  background-color: #ffdeda;
}
.primary > div {
  color: #ff4b39;
}
.secondary {
  border-color: #000000;
  background-color: #e5e5e5;
}
.secondary > div {
  color: #000000;
}
.tertiary {
  border-color: #ffffff;
  background-color: #007aff;
}
.tertiary > div {
  color: #ffffff;
}
.information {
  border-color: #007aff;
  background-color: #c5ddf8;
}
.information > div {
  color: #007aff;
}
/* Icon color */
.icon-primary g {
  fill: #ff4b39;
}
.icon-secondary g {
  fill: #000000;
}
.icon-tertiary g {
  fill: #ffffff;
}
.icon-information g {
  fill: #007aff;
}
