.listHeader {
  text-align: center;
  padding: 15px 15px;
  font-weight: 600;
  background-color: #ffff;
}
.listContainer {
  overflow-y: auto;
  height: 80vh;
}
.selectContainer {
  width: 100%;
  margin-bottom: 150px;
}
.sectionTextContainer {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  justify-content: space-between;
  cursor: pointer;
  margin: 2px 18px 2px 18px;
}
.sectionTextContainer p {
  cursor: pointer;
}
.sectionHeader {
  padding: 15px 10px;
}
