.modalContainer {
  position: fixed;
  display: grid;
  align-items: center;
  justify-items: center;
  top: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.childrenContainer {
  padding: 20px;
  display: flex;
  position: relative;

  @media screen and (max-width: 460px) {
    padding: 5px;
  }
}

.background {
  background-color: black;
  opacity: 60%;
  position: fixed;
  width: 100%;
  height: 100%;

  &.transparent {
    background-color: transparent;
  }
}
