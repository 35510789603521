.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.messageContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  color: #4a4a4a;

  .notified {
    display: flex;
    flex-direction: column;
  }

  .notifiedMessage {
    color: #1fc36a;
  }

  p {
    margin: 0;
    padding-left: 4px;
  }

  .title {
    text-transform: uppercase;
  }
}
