.wrapper {
  min-width: 73vw;
  padding: 2vw;
}
.message {
  text-align: center;
  font-weight: 400;
  font-size: 4.5vw;
}
.icon {
  text-align: center;
  margin-bottom: 4vw;
}
.actionButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.title {
  font-size: 5.5vw;
  font-weight: 700;
  margin-bottom: 3vw;
  text-align: center;
}
