.modalOuterWrapper {
  height: auto;
  overflow: auto;
  grid-area: menu;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  overflow-y: auto;
}
.image {
  width: 100%;
  object-fit: contain;
  position: relative;
  z-index: 1;
}
:global(.kiosk-web) .image {
  top: 0;
  position: fixed;
}
@media screen and (max-width: 772px) {
  .image {
    top: 0;
    position: fixed;
  }
}
.modalChildren {
  padding: 8px;
  width: 100%;
  z-index: 2;
  padding-bottom: 178px;
}
.modalChildren section {
  margin-bottom: 8px;
}
:global(.kiosk-web) .modalChildren {
  margin-bottom: 100px;
  padding-bottom: 25px;
}
@media screen and (max-width: 954px) {
  .modalChildren {
    top: 203px;
  }
}
@media screen and (max-width: 772px) {
  .modalChildren {
    margin-bottom: 65px;
    padding-bottom: 15px;
  }
}
.imageContainer {
  position: relative;
}
:global(.kiosk-web) .close p {
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 772px) {
  .close p {
    margin: 0;
    padding: 0;
  }
}
