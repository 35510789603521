.menuCustomisationContainer {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.bottomButtonWrapper {
  display: flex;
  width: 100%;
  height: 2rem;
  gap: 0.25rem;

  & > button {
    flex: 1;
  }

  & > div {
    width: 3.75rem;
  }

  &.leftNavMenu > div {
    width: 3.2rem;
  }
}

.menuSelectButton {
  :global(.kiosk-web) & {
    height: 2rem;
  }
}
