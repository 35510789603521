@import '../../../../hooks-and-hocs/theme/theme-data/standard-theme.less';

.container {
  margin-top: 0.5rem;
}

.addonsContainer {
  padding: 0rem 0.5rem 0.5rem;
}

.addonsTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 3.5rem;
}

.addonsNameText {
  font-size: 0.875rem;
  font-weight: 600;
  align-self: center;
  margin-right: 2rem;
  color: @standardColors[black];
  flex: 1.5;
  margin-block: 0.8rem;
}

.addonsPriceText {
  font-size: 0.875rem;
  font-weight: 600;
  align-self: center;
  color: @standardColors[black];
  flex: 1;
}

.addOnsSeparator {
  border-bottom: 0.1rem solid rgba(200, 199, 204, 0.5);
}

.productInfoContainer,
.addonButtonContainer {
  align-self: center;
  min-width: 5rem;
  min-height: 2rem;
  background-color: @standardColors[action-background-green] !important;

  div {
    font-size: 1rem !important;
    color: @standardColors[action-text-green] !important;
  }
}

.productInfoContainer {
  background-color: @standardColors[button-background-default] !important;
  width: 100%;
  margin-bottom: 0.2rem;
  div {
    color: @standardColors[grey] !important;
  }
}
