.title {
  font-size: 35px;
  font-weight: 600;
  line-height: 41px;
  margin: 6px 0;
}

.stickyButton {
  position: fixed;
  bottom: 20px;
  z-index: 1;
  padding: 0;

  @media screen {
    @media (min-width: 566px) {
      width: 540px;
    }

    @media (max-width: 565px) {
      width: 100%;
      max-width: 358px;
    }

    @media (max-width: 365px) {
      width: calc(100% - 16px);
    }
  }
}

.omniBarContainer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 1;
  padding: 0;

  :global(.kiosk-web) & {
    width: 100%;
    left: 0;
  }

  @media screen {
    @media (min-width: 566px) {
      width: 540px;
      left: unset;
    }

    @media (max-width: 565px) {
      width: 100%;
    }
  }
}
