.renderItem {
  margin: 10px;
  cursor: pointer;
}

.flatList {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}
