.orderId {
  padding-top: 25px;
  text-align: center;
  color: #454545;
}
@media screen and (min-width: 773px) {
  .orderId {
    padding-top: 11px;
  }
}
