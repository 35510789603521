.container {
  max-width: 375px;
  width: 100%;
  margin: auto auto;

  :global(.kiosk-web) & {
    max-width: 720px;
    margin: auto auto;
  }

  :global(.native-app) & {
    margin: 24px auto auto;
  }
}

.content {
  width: inherit;
  max-width: inherit;
  padding: 0px 8px 40px;
  justify-content: center;
}
