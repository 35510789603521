.comment {
  border-bottom: 1px solid #979797;
  padding: 0 8px 8px 8px;
  margin-bottom: 8px;
  text-align: center;
}
.comment p {
  font-size: 15px;
  font-weight: 600;
}
.comment .validationError {
  color: red;
  margin-top: 8px;
}
@media screen and (max-width: 772px) {
  .comment {
    text-align: left;
  }
}
