.statusWrapper {
  position: relative;
  display: inline-block;
  margin-left: -56px;
  height: 200px;
}
.statusContent {
  position: absolute;
  top: 0;
  align-items: center;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding-left: 80px;
  padding-right: 50px;
}
.trackerSubTitle {
  font-size: 0.8em;
  color: white;
  margin: 0;
  text-transform: uppercase;
}
.trackerStatusText {
  font-size: 1.75em;
  width: 200px;
  font-weight: 600;
  color: white;
  margin: 0;
}
