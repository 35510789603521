.nutritionalContainer {
  padding-bottom: 8px;
}
.buttonContainer {
  margin: 3px;
}
.buttonContainer > button {
  width: 100%;
}
:global(.kiosk-web) .buttonContainer > button {
  height: 70px;
  border-radius: 16px !important;
}
.buttonContainer > button > div {
  font-size: 16px !important;
}
:global(.kiosk-web) .buttonContainer > button > div {
  font-size: 26px !important;
}
.button {
  font-weight: bold;
  width: '100%';
}
