.prompt {
  min-height: 50px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  text-align: center;

  :global(.kiosk-web) & {
    font-size: 20px;
  }
}

.orderDetails {
  border-top: 1px solid #c8c7cc;

  > div {
    padding: 10px;
    border-bottom: 1px solid #c8c7cc;

    &:nth-last-child(2),
    &:last-child {
      border: none;
    }

    &:last-child {
      padding-top: 0;
    }
  }
}

.stickyButtonContainer {
  position: sticky;
  bottom: 7px;
  background-color: #ffffff;
  border-radius: 20px;

  > div {
    border-bottom: 1px solid #c8c7cc;
    padding: 10px 0;

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border: none;
      padding-bottom: 0;
    }
  }
}
