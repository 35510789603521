.buttonLabel {
  color: #202124;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  cursor: inherit;
}

.googleIcon {
  position: absolute;
  padding-top: 3px;
  left: 20px;
}
