.wrapper {
  display: flex;
  margin: 8px 0 7px;
  gap: 7px;
}
.wrapper .icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper .title {
  font-size: 15px;
  margin: 0;
  font-weight: 700;
}
.wrapper .subtitle {
  font-size: 15px;
  margin: 0;
  font-weight: 400;
}
