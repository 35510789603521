.wrapper {
  display: inline-block;
  vertical-align: top;
}
.wrapperDisabled {
  opacity: 0.5;
}
.inputContainer,
.inputContainerWithClearButton {
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  height: 44px;
  padding-left: 11px;
  padding-right: 11px;
  position: relative;
}
:global(.kiosk-web) .inputContainer {
  height: 72px;
  padding-left: 16px;
  padding-right: 16px;
}
.inputContainerWithClearButton {
  padding-right: 0;
}
.prefix {
  margin-top: 21px;
}
.placeholder,
.placeholderHasValue,
.placeholderHasFocus,
.placeholderHasError {
  color: rgba(0, 0, 0, 0.54);
  left: 11px;
  position: absolute;
  top: 11px;
  transition: top 0.1s;
}
:global(.kiosk-web) .placeholder {
  left: 16px;
  top: 16px;
  font-size: 25px;
}
.placeholderHasValue,
.placeholderHasFocus,
.placeholderHasError {
  font-size: 12px;
  top: 7px;
}
:global(.kiosk-web) .placeholderHasValue {
  font-size: 22px;
  left: 16px;
  top: 11px;
}
.placeholderHasFocus {
  color: #2979ff;
}
:global(.kiosk-web) .placeholderHasFocus {
  font-size: 22px;
  left: 16px;
  top: 11px;
}
.placeholderHasError {
  color: #e21836;
}
:global(.kiosk-web) .placeholderHasError {
  font-size: 22px;
  left: 16px;
  top: 11px;
}
.input,
.inputHasFocus,
.inputHasError {
  background-color: transparent;
  border: none;
  font-size: 15px;
  outline: none;
  padding-bottom: 7px;
  padding-top: 21px;
  width: 100%;
  z-index: 2;
}
:global(.kiosk-web) .input {
  font-size: 25px;
  padding-top: 50px;
  padding-bottom: 15px;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.inputHasFocus {
  caret-color: #2979ff;
}
:global(.kiosk-web) .inputHasFocus {
  font-size: 25px;
  padding-top: 50px;
  padding-bottom: 15px;
}
.inputHasError {
  caret-color: #e21836;
}
:global(.kiosk-web) .inputHasError {
  font-size: 22px;
  padding-top: 50px;
  padding-bottom: 20px;
}
.underline,
.underlineHasFocus,
.underlineHasError {
  border: 1px solid rgba(0, 0, 0, 0.54);
  height: 0px;
  position: relative;
  top: -2px;
  width: 100%;
}
.underlineHasFocus {
  border-color: #2979ff;
}
.underlineHasError {
  border-color: #e21836;
}
.errorContainer {
  color: #e21836;
  font-size: 11px;
  min-height: 14px;
  padding-left: 11px;
  padding-right: 11px;
  position: relative;
  top: -1px;
}
:global(.kiosk-web) .errorContainer {
  font-size: 22px;
}
.spinner {
  margin-bottom: auto;
  margin-top: auto;
}
.clearButtonContainer {
  background-color: transparent;
  height: 44px;
  float: left;
  margin-bottom: auto;
  margin-top: auto;
  padding-top: 3px;
  cursor: pointer;
}
