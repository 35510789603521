body {
  margin: 0;
  padding: 0;
  background: #f6f6f6;
  overflow-y: scroll;
}

body,
select {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: normal;
  font-size: 1rem;
}

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  color: #4183c4;
  text-decoration: none;

  &:hover {
    color: #1e70bf;
    text-decoration: none;
  }
}

// Hack to prevent the blue background colour for the autofilled input fields
// https://stackoverflow.com/a/29350537
input:-webkit-autofill {
  transition: all 0s 99999999s;
}

@font-face {
  font-family: 'pizza_pressfill';
  src: url('../../res/assets/fonts/pizzapress-fill-webfont.woff2') format('woff2'),
    url('../../res/assets/fonts/pizzapress-fill-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'pizza_pressoutline';
  src: url('../../res/assets/fonts/pizzapress-outline-webfont.woff2') format('woff2'),
    url('../../res/assets/fonts/pizzapress-outline-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'pizza_pressreverse_shadow';
  src: url('../../res/assets/fonts/pizzapress-reverseshadow-webfont.woff2') format('woff2'),
    url('../../res/assets/fonts/pizzapress-reverseshadow-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
