.divider {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 7px;
  font-size: 12px;
  text-transform: uppercase;
  color: #4a4a4a;
  font-weight: 700;
}

.divider:after,
.divider:before {
  content: ' ';
  height: 1px;
  width: 100%;
  display: inline-block;
  background-color: #c8c7cc;
  opacity: 0.5;
}
