.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px;
  gap: 10px;

  .messageContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #4a4a4a;
    gap: 10px;

    .messageBody {
      display: flex;
      flex-direction: column;

      p {
        margin: 0;
      }

      .title {
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
}
