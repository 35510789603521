@import '../../../../hooks-and-hocs/utils/breakpoints/breakpoints.less';
@import '../../../../hooks-and-hocs/theme/theme-data/standard-theme.less';

.animationView {
  z-index: 6;
  position: fixed;
  bottom: 10vh;
  @media @mobile {
    margin-inline: 16px;
    left: 0px;
    right: 0px;
  }
  @media @not-mobile {
    width: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1139px) {
      width: 500px;
    }
    @media (max-width: 954px) {
      width: 350px;
    }
  }
}

.confirmationContent {
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  padding: 8px;
  background-color: #e5f1ff;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0px 20px 10px;
  border-radius: @borderRadius[web-medium];
  @media @not-mobile {
    width: 375px;
  }
}
.imgColumn {
  width: 72px;
  display: grid;
  justify-content: center;
  align-items: center;
}

.textColumn {
  flex: 1;
  padding: 12px 0px 12px 8px;
}
.confirmationTitle {
  font-size: 18px;
  font-weight: 600;
}
.confirmationDescription {
  font-weight: 400;
  font-size: 18px;
}
