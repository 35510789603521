.container {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 773px) {
  .container {
    align-items: center;
  }
}
.titleContainer {
  display: 'block';
  text-align: center;
}
.titleText {
  margin-top: 50px;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.02px;
  text-align: center;
  margin-bottom: 26px;
}
@media screen and (max-height: 820px) {
  .titleText {
    margin-top: 24px;
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 772px) {
  .titleText {
    margin-top: 20px;
    font-size: 18px;
    margin-bottom: 16px;
  }
}
:global(.kiosk-web) .titleText {
  font-size: 35px;
}
.buttonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
@media screen and (max-height: 830px) {
  .buttonsContainer {
    margin-top: 0px;
  }
}
@media screen and (max-width: 772px) {
  .buttonsContainer {
    display: block;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1;
  }
}
:global(.kiosk-web) .buttonsContainer {
  display: block;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
}
@media screen and (min-width: 773px) {
  .productContainer {
    display: flex;
    max-width: 800px;
    flex-flow: row wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 772px) {
  .productContainer {
    flex-direction: column;
    padding-inline: 16px;
    padding-bottom: 40px;
  }
}
.productInfoContainer {
  align-self: center;
  min-width: 5rem;
  min-height: 2rem;
  background-color: transparent !important;
}
@media screen and (min-width: 773px) {
  .productInfoContainer {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 772px) {
  .productInfoContainer {
    margin-top: -40px;
  }
}
