.crumbsContainer {
  display: flex;
  margin: 0px 0px 11px 0px;
  align-items: center;
}
.crumb {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-top-left-radius: 21px;
  border-bottom-left-radius: 21px;
  box-shadow: -2px 0px 4px rgba(137, 137, 137, 0.5);
  position: relative;
  height: 42px;
}
:global(.kiosk-web) .crumb {
  height: 75px;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  box-shadow: -5px 0px 10px rgba(137, 137, 137, 0.5);
}
.crumbsContainer > div:last-child {
  border-top-right-radius: 21px;
  border-bottom-right-radius: 21px;
}
:global(.kiosk-web) .crumbsContainer > div:last-child {
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}
.crumbText {
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 600;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  width: 100px;
  text-align: center;
}
:global(.kiosk-web) .crumbText {
  font-size: 27px;
  width: 150px;
}
.innerCurve {
  position: absolute;
  right: -21px;
  width: 42px;
  height: 42px;
}
:global(.kiosk-web) .innerCurve {
  right: -42px;
  width: 75px;
  height: 75px;
}
