.iframeWrapper {
  border-top: 1px solid #c8c7cc;
  padding-top: 0px;
  display: flex;
  position: relative;
  width: 100%;
  height: 400px;

  @media screen and (max-width: 772px) {
    height: 400px;
  }

  @media screen and (max-width: 565px) {
    height: 400px;
  }

  iframe {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
