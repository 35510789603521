.countWrapper {
  min-width: 24px;
  min-height: 24px;
  border-radius: 50px;
  background-color: #ff0000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  position: absolute;
  right: 40px;
}
.iconWrapper {
  min-width: 16px;
  min-height: 16px;
  border-radius: 8px;
  background-color: #ff0000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
