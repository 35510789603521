.ellipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 500ms infinite;
  animation: ellipsis steps(4, end) 500ms infinite;
  content: '\2026';
  /* ascii code for the ellipsis character */
  width: 0px;
}
@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
.status {
  display: flex;
  align-items: center;
}
.alert {
  padding: 0px 2px;
  font-size: 12px;
  font-weight: 800;
  height: 100%;
  color: #ff5c47;
  border: 2px solid #ff5c47;
  border-radius: 5px;
}
.statusMessage {
  padding: 0px 2px;
  font-size: 12px;
  font-weight: 800;
  height: 100%;
}
.storeDetailsContainer {
  background-color: #e5e5e5;
}
.storeDetailsContainer > div > div:nth-child(2) {
  width: 100%;
}
.textContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.normalText {
  font-weight: normal;
}
.messageText {
  font-weight: 600;
  align-items: center;
  overflow: hidden;
  padding: 10px;
  gap: 10px;
}
.addBorderTop {
  border-top: 0.6px solid #a3a3a3;
}
