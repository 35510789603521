.switchContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.switchLabel {
  font-size: 12px;
  font-weight: 700;
  padding-right: 3px;
  text-transform: uppercase;
  color: #4a4a4a;
}
