.menuCustomisationContainer {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.bottomButtonWrapper {
  display: flex;
  width: 100%;
  height: 2rem;
  gap: 0.25rem;
}
.bottomButtonWrapper > button {
  flex: 1;
}
.bottomButtonWrapper > div {
  width: 3.75rem;
}
.bottomButtonWrapper.leftNavMenu > div {
  width: 3.2rem;
}
:global(.kiosk-web) .menuSelectButton {
  height: 2rem;
}
