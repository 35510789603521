.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .icon {
    width: 60px;
    height: 60px;
    border-width: 1px;
    border-style: solid;
    margin: 8px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .text {
    font-weight: 600;
    font-size: 15px;
    margin-left: 8px;
  }
}
