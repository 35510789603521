.container {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 400px;
  align-items: center;
  padding: 8px;
  flex-direction: column;
}
.title {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}
.errorMessage {
  font-size: 1em;
  color: red;
  margin-bottom: 1em;
  text-align: center;
}
