.compactWrapper {
  .compactTitle {
    font-size: 15px;
    font-weight: 600;
  }

  .options > .option {
    font-size: 14px;
    padding: 0;

    &:not(:first-of-type) {
      border: none;
      grid-auto-flow: unset;

      > p {
        margin: 5px 0;
        font-size: 11px;
        font-weight: 600;
      }
    }
  }
}

.options {
  display: grid;
  border: 0;
  outline: 0;
  padding: 0;

  > .option {
    display: grid;
    padding: 10px 0;
    grid-auto-flow: column;

    &:first-of-type {
      padding-top: 0;
    }

    &:not(:first-of-type) {
      border-top: 1px solid #e3e3e5;
    }
    :global(.kiosk-web) & {
      input {
        width: 30px;
        height: 30px;
      }

      label {
        font-size: 25px;
      }
    }
  }
}
