.container {
  text-align: center;
}

.changeLanguage {
  margin-bottom: 0.3125rem;
  color: white;
  font-size: large;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.15625rem;
}

.languageButton {
  display: flex;
  justify-content: center;
  margin-bottom: 0.3125rem;
  font-size: large;
  gap: 0.3125rem;
}
