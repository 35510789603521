.container {
  margin-top: 0.5rem;
}
.addonsContainer {
  padding: 0rem 0.5rem 0.5rem;
}
.addonsTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 3.5rem;
}
.addonsNameText {
  font-size: 0.875rem;
  font-weight: 600;
  align-self: center;
  margin-right: 2rem;
  color: #000000;
  flex: 1.5;
  margin-block: 0.8rem;
}
.addonsPriceText {
  font-size: 0.875rem;
  font-weight: 600;
  align-self: center;
  color: #000000;
  flex: 1;
}
.addOnsSeparator {
  border-bottom: 0.1rem solid rgba(200, 199, 204, 0.5);
}
.productInfoContainer,
.addonButtonContainer {
  align-self: center;
  min-width: 5rem;
  min-height: 2rem;
  background-color: rgba(31, 195, 106, 0.2) !important;
}
.productInfoContainer div,
.addonButtonContainer div {
  font-size: 1rem !important;
  color: #5cc073 !important;
}
.productInfoContainer {
  background-color: #efeff4 !important;
  width: 100%;
  margin-bottom: 0.2rem;
}
.productInfoContainer div {
  color: #4a4a4a !important;
}
