.adyen-saved-payment-container {
  display: flex;
  align-items: center;
  background-color: #eee;
  border: 2px solid #eee;
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 10px;

  &.selectable {
    cursor: pointer;
  }

  &.selected {
    background-color: transparent;
    border-color: #1fc36a;
  }
}

.brand-icon {
  height: 24px;
  width: 40px;
}

.adyen-saved-card-wrapper {
  flex: 1;
  margin-left: 15px;

  .adyen-saved-card {
    color: #4a4a5a;
    font-size: 12px;
    font-weight: bold;
  }

  .adyen-saved-card-digits {
    font-size: 15px;
    font-weight: bold;
  }
}

.cardContainer {
  box-shadow: 0px 0.5px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  background-color: white;
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.contentContainer {
  width: 360px;
  max-height: 90vh;
  padding: 15px 10px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
}

.changeButton {
  color: #4183c4;
  font-weight: bold;
  margin-right: 5px;
  cursor: pointer;
}

.contentHeader {
  margin: 0 0 15px 0;
  font-size: 20px;
}
