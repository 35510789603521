.appleButton {
  width: 100%;
  margin: '10px 0';
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: black;
}

.buttonLabel {
  color: #ffffff;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  cursor: inherit;
}

.icon {
  position: absolute;
  padding-bottom: 3px;
  left: 20px;
}
