@import '../../../hooks-and-hocs/theme/theme-data/standard-theme.less';

.svgMixin(@size) {
  svg {
    height: @size;
    width: @size;
  }
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 0;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 1.125rem;
  cursor: pointer;
  background-color: @standardColors[action-standard];
  .svgMixin(2rem);
}

.disabled {
  color: @standardColors[action-standard];
  background-color: @standardColors[action-text-disabled];
}

.text {
  width: 2.5rem;
  font-size: 1.375rem;
  font-weight: 600;
  text-align: center;
  outline: none;
  border: none;
  margin: 0.25rem 0px;
  transition: width 0.1s;
  background-color: transparent;
}

@media screen and (max-width: 772px) {
  .controls {
    padding: 5px;
  }
  .button {
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 15px;
    .svgMixin(28px);
  }
  .text {
    width: 3.2rem;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  .button {
    padding: 2px 0 0 0;
  }
}
