.legendWrapper {
  pointer-events: none;
}
.legendContainer {
  padding: 2px;
}
.legendCode {
  font-size: 15px;
}
:global(.kiosk-web) .itemCode {
  font-size: 18px;
}
.legendImage {
  width: 29px;
}
