.wrapper a {
  font-weight: bold !important;
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.54);
}
.wrapper {
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  color: rgba(0, 0, 0, 0.54);
  line-height: 14px;
}

.lowerText {
  margin-top: 8px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 12px;
  letter-spacing: normal;
  margin-bottom: 10.6px;
}

.lowerParagraph {
  margin-top: 12px;
}

.hiddenText {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.54);
  line-height: 14px;
  letter-spacing: normal;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}
