@import '../../../../hooks-and-hocs/utils/breakpoints/breakpoints.less';

.container,
.containerOutline {
  margin-bottom: 12px;

  @media @not-mobile {
    width: 244px;
    height: 277px;
    margin: 6px;
  }
  :global(.kiosk-web) & {
    width: 332px;
    height: 375px;
    margin: 10px;
  }
}

.containerOutline {
  border: 1px solid #007aff;
}

.container:hover,
.containerOutline:hover {
  box-shadow: 0px 0.5px 10px 0px rgba(0, 0, 0, 0.3);
}

.innerContainer {
  @media @mobile {
    display: flex;
    flex-direction: row;
  }
}

.checkboxContainerRow {
  @media @not-mobile {
    display: flex;
    position: relative;
    background-color: #007aff;
  }
}

.checkboxContainer {
  position: absolute;
  right: 15px;
  margin-top: 8px;

  @media @not-mobile {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
  }
}

.checkboxWrapper {
  display: flex;
  background-color: greenyellow;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.imageContainer {
  display: flex;
  position: relative;
  justify-content: center;
}

.image {
  margin-top: 12px;
  width: 220px;
  height: 189px;
  object-fit: cover;
  border-radius: 12px;

  @media @mobile {
    margin: 10px;
    width: 120px;
    height: 80px;
  }
  :global(.kiosk-web) & {
    border-radius: 8px;
    margin-top: 16px;
    width: 307px;
    height: 264px;
  }
}

.title {
  margin-top: 12px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media @mobile {
    padding-top: 8px;
    font-size: 14px;
  }
  :global(.kiosk-web) & {
    font-size: 22px;
  }
}

.price {
  font-size: 14px;
  font-weight: 500;
  color: #6e6e6e;
  margin-top: 4px;
  margin-bottom: 0px;

  @media @mobile {
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
  }
  :global(.kiosk-web) & {
    font-size: 18px;
  }
}

.textContainer {
  padding-inline: 12px;
  @media @mobile {
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.legends {
  display: flex;
  justify-content: center;
  justify-content: flex-end;
  position: absolute;
  left: 16px;
  bottom: 2px;

  @media @mobile {
    left: 13px;
    bottom: 10px;
  }
  img {
    width: 20px;

    :global(.kiosk-web) & {
      width: 30px;
    }
  }
}
