@import '../../../hooks-and-hocs/utils/breakpoints/breakpoints.less';

.container {
  margin: auto;
  margin-top: 0;
  padding: 10px;
  align-self: center;
  max-width: 360px;
  width: 100%;
  -ms-overflow-style: none;

  :global(.kiosk-web) & {
    margin-top: 24px;
    max-width: 650px;
    max-height: none !important;
    padding: unset;
  }

  @media @mobile {
    max-height: none !important;
  }

  @media @not-mobile {
    :not(:global(.kiosk-web)) &.withOrderButton {
      overflow-y: auto;
    }
  }
}

.container > * {
  width: 100%;
}

.container::-webkit-scrollbar {
  display: none;
}

.voucherDetails {
  border-top: 1px solid #c8c7cc;
}

.voucherDetails > div {
  padding: 0.625rem;
  border-bottom: 1px solid #c8c7cc;
}

.voucherDetails > div:last-child {
  border-bottom: none;
}

.orderButtonContainer {
  margin-top: 8px;
}
