.serviceMethodOptions {
  border: 0;
  outline: 0;
  padding: 0;
}
.serviceMethodOptions > div {
  padding: 10px 0;
  margin: 0;
}
.serviceMethodOptions > div:first-child {
  padding-top: 0;
}
.serviceMethodOptions > div:not(:first-child) {
  border-top: 1px solid #e3e3e5;
}
.serviceMethodOption {
  display: flex;
  margin-bottom: 4px;
}
.serviceMethodOption label {
  cursor: pointer;
  padding-top: 4px;
}
.serviceMethodOption input {
  height: 20px;
  width: 20px;
  margin-left: 0;
  margin-right: 10px;
}
.flexBoxWrapper {
  display: flex;
}
.flexBoxWrapper > div:first-child {
  margin: 0;
  flex: 1;
}
.learnMoreButton {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  top: 7px;
  right: 6px;
  cursor: pointer;
  margin-left: 6px;
}
.extraMessage {
  display: flex;
  margin-top: 10px;
  align-items: center;
}
.extraMessage > svg {
  margin-right: 8px;
}
.extraMessage > p {
  margin: 0;
  flex: 1;
}
