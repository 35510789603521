.errorMessage {
  padding-top: 4px;

  p {
    color: #e21836;
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 4px 0;
  }
}
