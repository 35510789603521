.headerTitle {
  font-size: 28px;
  font-weight: 600;
  :global(.kiosk-web) & {
    font-size: 56px;
    font-weight: 600;
  }
}

.headerDetailBox {
  margin-bottom: 10px;
  :global(.kiosk-web) & {
    margin-top: 50px;
    margin-bottom: 20px;
  }
}

.headerPrice {
  font-size: 15px;
  :global(.kiosk-web) & {
    font-size: 25px;
  }
}
