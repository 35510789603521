.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.name {
  font-size: 15px;
  text-align: center;
  line-height: 15px;
  margin: 0;
  :global(.kiosk-web) & {
    font-size: 25px;
    line-height: 45px;
    margin: -10px 0 0 0;
  }
}

.price {
  font-size: 15px;
  text-align: center;
  line-height: 20px;
  font-weight: 600;
  margin: 0;
  :global(.kiosk-web) & {
    font-size: 25px;
  }
}

.iconContainer {
  justify-content: center;
  align-items: center;
}
