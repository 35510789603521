.wrapper {
  background-color: #ffffff;
  width: 100%;
  padding: 34px 8px 80px;
  margin-top: auto;
}
@media screen and (min-width: 773px) {
  .wrapper {
    padding: 33px 8px 15px;
  }
  :global(.kiosk-web) .wrapper {
    padding: 34px 8px 80px;
  }
}
.wrapper hr {
  width: 50px;
}
.wrapper a,
.wrapper p,
.wrapper #ot-sdk-btn {
  display: block;
  border: none !important;
  font-size: 15px !important;
  line-height: 20px !important;
  letter-spacing: -0.16px;
  margin: 0;
  padding: 0 !important;
}
.wrapper a,
.wrapper a:hover,
.wrapper p,
.wrapper #ot-sdk-btn {
  color: #454545 !important;
  background-color: transparent !important;
}
.terms {
  padding-bottom: 5.4px;
  max-width: 1137px;
  margin: auto;
}
:global(.kiosk-web) .terms {
  max-width: unset;
}
.terms p,
.terms b {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.12px;
  color: #454545;
}
:global(.kiosk-web) .terms p,
:global(.kiosk-web) .terms b {
  font-size: 18px;
  text-align: justify;
  line-height: unset;
  letter-spacing: unset;
  color: unset;
}
.terms b {
  font-weight: bold;
}
.legal {
  padding-top: 25px;
  text-align: center;
}
@media screen and (min-width: 773px) {
  .legal {
    padding-top: 11px;
  }
}
.kJ {
  text-align: center;
  padding-bottom: 11.4px;
  max-width: 1137px;
  margin: auto;
}
.kJ p {
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.24px;
  color: #000;
  margin: 0;
}
.divider {
  display: block;
  max-width: 375px;
  height: 2px;
  background-color: #dfdfdf;
  margin: 16.4px auto;
}
.topContainer {
  display: flex;
  justify-content: center;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.innerContainer {
  display: flex;
}
.nutrition {
  display: flex;
  margin-top: 20px;
}
.storeHealth {
  display: flex;
  margin-top: 20px;
}
.row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.desktopRow {
  display: flex;
  flex-direction: row;
}
