.accessory {
  display: grid;
  grid-auto-flow: column;
  align-items: baseline;
  justify-content: flex-end;
  border: none;
  cursor: pointer;
  svg path {
    fill: #979797;
  }
  &:hover svg path {
    fill: #4a4a4a;
  }

  :global(.kiosk-web) & > svg {
    width: 31px;
    height: 31px;
  }
}
