.row {
  overflow-x: hidden;
  padding: 4px 0;
}
.wrapper {
  display: flex;
  flex-direction: row;
}
.moveableWrapper {
  width: 100%;
  overflow: hidden;
}
.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  border-radius: 40px;
  background-color: #eee;
  justify-content: space-between;
}
.imageTitleGroup {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.thumb {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 12px;
}
.title {
  font-weight: 600;
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
:global(.kiosk-web) .title {
  font-size: 25px;
}
.title p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  transition: ease-in-out 0.1s;
}
.leftButtonPriceWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 6px;
}
.rightButtonQuantityWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 12px;
}
.plusDisabled {
  opacity: 0.5;
}
.price {
  margin-right: 5px;
  font-size: 10px;
}
:global(.kiosk-web) .price {
  font-size: 22px;
}
.quantity {
  margin: 0 5px;
  width: 12px;
  font-size: 18px;
  user-select: none;
  white-space: nowrap;
}
:global(.kiosk-web) .quantity {
  font-size: 28px;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 100%;
  cursor: pointer;
}
:global(.kiosk-web) .button svg {
  height: 40px;
  width: 40px;
}
.buttonDisabled {
  background-color: #e0e0e0 !important;
  cursor: no-drop;
}
@media screen and (max-width: 772px) {
  .content {
    padding: 4px;
    border-radius: 18px;
    height: 33px;
    overflow-y: hidden;
  }
  .thumb {
    width: 28px;
    height: 28px;
    margin-right: 4px;
  }
  .leftButtonPriceWrapper {
    margin-right: 0px;
  }
  .rightButtonQuantityWrapper {
    padding-right: 4px;
  }
  .title {
    font-size: 14px;
  }
}
