.cardContainer {
  box-shadow: 0px 0.5px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  background-color: white;
  z-index: 1;
  position: relative;
  overflow: hidden;
}
.contentContainer {
  width: 390px;
  max-width: 95vw;
  min-height: 250px;
  max-height: 90vh;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}
.popupWarningIcon {
  width: 75px;
  margin-top: 15px;
  display: flex;
  align-self: center;
}
.contentBody {
  margin: 30px 16px;
}
