@padding: 5px;

.nutritionalLine {
  display: flex;
  width: 100%;
}

.nutritionalName {
  display: inline;
  padding-left: @padding;
  flex: 1;
}

.nutritionalValue {
  display: inline;
  text-align: right;
  justify-self: flex-end;
  padding-right: 3px;
}

.nutritionalUnit {
  display: inline;
  padding-right: @padding;
  text-align: right;
  justify-self: flex-end;
}
