.scale {
  border-bottom: 1px solid #979797;
  padding: 0 8px 8px 8px;
  margin-bottom: 8px;
  text-align: center;
}
.scale p {
  font-weight: 600;
}
.scale .validationError {
  color: red;
  margin-top: 0;
}
@media screen and (max-width: 772px) {
  .scale {
    text-align: left;
  }
}
.centre {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ticks {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  list-style: none;
  padding: 0;
  margin: 0 0 16px 0;
}
.tick {
  align-items: center;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  height: 68px;
  justify-content: center;
  padding: 0 10px;
  border-right: 1px solid #ffffff;
}
@media screen and (max-width: 357px) {
  .tick {
    padding: 0 8px;
  }
}
.inactive {
  background-color: #979797;
}
.tick-0,
.tick-1,
.tick-2,
.tick-3,
.tick-4,
.tick-5,
.tick-6 {
  background-color: #ff5c47;
}
.tick-0:hover,
.tick-1:hover,
.tick-2:hover,
.tick-3:hover,
.tick-4:hover,
.tick-5:hover,
.tick-6:hover {
  background-color: rgba(255, 92, 71, 0.9);
}
.tick-7,
.tick-8 {
  background-color: #ffce00;
}
.tick-7:hover,
.tick-8:hover {
  background-color: rgba(255, 206, 0, 0.9);
}
.tick-9,
.tick-10 {
  background-color: #56c84d;
}
.tick-9:hover,
.tick-10:hover {
  background-color: rgba(86, 200, 77, 0.9);
}
