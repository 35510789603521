.title {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}

.loginContainer {
  width: 100%;
  margin-top: 18px;
  margin-bottom: 18px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alreadyText {
  margin-right: 7px;
}
