.terms {
  padding: 2px 0 0 0;
  font-size: 10pt;
  font-weight: 700;
  cursor: pointer;
  color: #007aff;
}
.termsAndConditions {
  font-size: 10px;
  padding: 0 0 6px 0;
}
