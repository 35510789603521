.popupTitle {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  text-align: center;
}
.hr {
  margin: 0px -8px 0 -8px;
  border-top: 0.5px solid rgba(200, 199, 204, 0.5);
}
.sectionTitle {
  font-size: 0.9rem;
  font-weight: 600;
  text-align: left;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-bottom: 4px solid rgba(200, 199, 204, 0.5);
  margin: 0 -8px;
  padding: 0 0.5rem;
  font-size: 0.9rem;
}
.wrapper > h2 {
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0px;
}
.wrapper:last-child {
  border-bottom: none;
}
.wrapper > hr:last-child {
  display: none;
}
.wrapper > button {
  height: 44px;
}
.orderDetailsPill {
  padding-bottom: 0.5rem;
}
.roundUpForCharity {
  padding-bottom: 0.5rem !important;
}
.inlineOfferItem {
  margin-top: -0.5rem;
}
.deliveryInstructions {
  padding-bottom: 0.5rem !important;
}
.deliveryInstructions > div {
  margin-top: 0 !important;
}
.deliveryInstructions > div > section {
  border-radius: 0 !important;
  box-shadow: none !important;
}
.deliveryInstructions > div > section > header {
  padding: 0 !important;
}
.deliveryInstructions > div > section > header > h3 {
  font-size: 0.9rem;
  font-weight: 600;
}
.deliveryInstructions > div > section > div {
  padding: 0 !important;
  margin-top: 0.5rem !important;
}
.deliveryInstructions > div > section > div > div {
  padding: 0 !important;
}
.wcaAddressContainer {
  padding-bottom: 0.5rem !important;
}
.wcaAddressContainer > div {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.wcaAddressContainer > div > section {
  border-radius: 0 !important;
  box-shadow: none !important;
}
.wcaAddressContainer > div > section > header {
  padding: 0 !important;
}
.wcaAddressContainer > div > section > header > h3 {
  font-size: 0.9rem;
  font-weight: 600;
}
.wcaAddressContainer > div > section > div {
  padding: 0 !important;
  margin-top: 0.5rem !important;
}
.wcaAddressContainer > div > section > div > div {
  padding: 0 !important;
  margin: 0 !important;
}
.link {
  font-size: 11px;
  margin-top: -0.5rem;
}
.link > div > p {
  font-weight: 600 !important;
}
.payButtonOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 999;
  cursor: not-allowed;
}
