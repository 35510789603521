.iframeWrapper {
  margin-top: 8px;

  iframe {
    border: 0;
    width: 100%;
    min-width: 100%;
    height: 100%;
  }
}
