.hr {
  margin: 10px -10px 0 -10px;
  border-top: 0.5px solid rgba(200, 199, 204, 0.5);
}
.yourDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.yourDetails > svg {
  display: flex;
  flex: 1;
  justify-content: left;
  align-items: left;
  color: var(--Foreground-Action-Blue, #007aff);
}
.yourDetails > label {
  display: flex;
  flex: 8;
  justify-content: left;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
}
.yourDetails > p {
  display: flex;
  flex: 8;
  justify-content: right;
  align-items: right;
  font-size: 12px;
  font-weight: 400;
  color: #4a4a4a;
  margin: 0 0 5px 0;
}
.sectionTitle {
  font-size: 15px;
  font-weight: 600;
  text-align: left;
}
