.comment {
  border-bottom: 1px solid rgba(151, 151, 151, 1);
  padding: 0 8px 8px 8px;
  margin-bottom: 8px;
  text-align: center;

  p {
    font-size: 15px;
    font-weight: 600;
  }

  .validationError {
    color: red;
    margin-top: 8px;
  }

  @media screen and (max-width: 772px) {
    text-align: left;
  }
}
