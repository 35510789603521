.container {
  display: flex;
  align-items: center;

  &.reversed {
    flex-direction: row-reverse;
  }
}
.checkbox {
  cursor: pointer;
  width: 40px;
  height: 20px;
  position: relative;
  padding: 0 5px;
  visibility: hidden;

  &:before {
    content: '';
    width: 40px;
    height: 20px;
    display: block;
    top: 0;
    left: 0;
    background-color: #666;
    border-radius: 20px;
    transition-duration: 0.3s;
    visibility: visible;
    position: absolute;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 16px;
    transition-duration: 0.3s;
    visibility: visible;
  }

  &:checked {
    &:before {
      background-color: #1fc36a;
      transition-duration: 0.3s;
    }

    &:after {
      left: calc(100% - 1px);
      transform: translateX(-100%);
    }
  }

  &:disabled {
    cursor: default;

    &:before {
      background: #efeff4;
    }
  }
}
