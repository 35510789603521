.actionButton {
  justify-content: center;
  min-height: 32px;
  width: 100%;
  font-size: 14px !important;
  font-weight: 600 !important;
  :global(.kiosk-web) & {
    min-height: 64px;
    font-size: 28px !important;
  }
}

.buttonContainer {
  justify-content: center;
  > button {
    width: 100%;
    min-height: 32px;

    :global(.kiosk-web) & {
      min-height: 64px;
    }

    > div {
      font-size: 14px !important;
      font-weight: 600 !important;

      :global(.kiosk-web) & {
        font-size: 28px !important;
      }
    }
  }
}
