.container {
  border-radius: 50px;
  display: flex;
  flex: 1;
  cursor: pointer;
}
.container.backBackground {
  box-shadow: 0 0.5px 0.125rem 0 rgba(0, 0, 0, 0.13);
  background-color: rgba(0, 0, 0, 0.45);
}
.container.notInNavBar {
  position: absolute;
  top: 6.5rem;
}
.text {
  height: 19px;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.02px;
  padding-left: 4px;
  margin: 0;
  line-height: 19px;
  color: #007aff;
}
:global(.kiosk-web) .text {
  height: 32px;
  font-size: 30px;
  line-height: normal;
  letter-spacing: 0;
  margin-bottom: 5px;
}
.backBackground .text {
  color: #fff;
}
.innerContainer {
  padding-left: 10px;
  padding-right: 14px;
  display: flex;
  align-items: center;
  height: 33px;
}
:global(.kiosk-web) .innerContainer svg {
  width: 30px;
  height: 20px;
}
.innerContainer svg polygon {
  fill: #007aff;
}
.backBackground .innerContainer svg polygon {
  fill: #fff;
}
