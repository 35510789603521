.orderEta {
  color: #1fc36a;
  text-align: center;
  margin: 0 4px 4px 4px;
  border: 6px solid #1fc36a;
  border-radius: 10px;
}
.orderEta p {
  font-size: 20px;
  margin: 8px;
}
.orderEta span {
  padding: 8px;
  display: block;
}
