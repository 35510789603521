.cardContainer {
  box-shadow: 0px 0.5px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  background-color: white;
  z-index: 1;
  position: relative;
  overflow: hidden;
}
.contentContainer {
  width: 400px;
  max-width: 95vw;
  min-height: 250px;
  max-height: 90vh;
  padding: 5px 15px 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contentContainer > div:first-child {
  overflow-y: scroll;
}
@media screen and (min-width: 426px) {
  .contentContainer {
    width: 500px;
  }
}
@media screen and (min-width: 1440px) {
  .contentContainer {
    width: 900px;
  }
}
.closeButton {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  top: 7px;
  right: 6px;
  position: absolute;
  cursor: pointer;
  background-color: #4a4a4a;
}
.cardHeader {
  margin: 15px 15px 0 15px;
  font-size: 20px;
}
.contentFooter {
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;
}
.contentFooter > div:first-child {
  font-weight: bold;
}
.contentFooter button {
  margin-top: 10px;
}
@media screen and (max-width: 420px) {
  .contentFooter button > div:first-child {
    font-size: 14px !important;
  }
}
.aboutPricingContainer {
  width: 100%;
  font-weight: 700;
  font-size: 15;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
