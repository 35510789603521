.title {
  padding: 2px 0 0 0;
  font-size: 10pt;
  font-weight: 700;
  cursor: pointer;
  color: #007aff;
}
.details {
  font-size: 12px;
  font-weight: 600;
  padding: 4px 0;
}
