.modalOuterWrapper {
  height: auto;
  overflow: auto;
  grid-area: menu;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  overflow-y: auto;
}

.image {
  width: 100%;
  object-fit: contain;
  position: relative;
  z-index: 1;

  :global(.kiosk-web) & {
    top: 0;
    position: fixed;
  }

  @media screen {
    @media (max-width: 772px) {
      top: 0;
      position: fixed;
    }
  }
}

.modalChildren {
  padding: 8px;
  width: 100%;
  z-index: 2;
  padding-bottom: 178px;

  section {
    margin-bottom: 8px;
  }

  :global(.kiosk-web) & {
    margin-bottom: 100px;
    padding-bottom: 25px;
  }

  @media screen {
    @media (max-width: 954px) {
      top: 203px;
    }

    @media (max-width: 772px) {
      margin-bottom: 65px;
      padding-bottom: 15px;
    }
  }
}

.imageContainer {
  position: relative;
}

.close p {
  :global(.kiosk-web) & {
    margin: 0;
    padding: 0;
  }

  @media screen {
    @media (max-width: 772px) {
      margin: 0;
      padding: 0;
    }
  }
}
