.availablePizzaLabel {
  font-weight: 500;
  color: #4a4a4a;
}

.wrapper {
  display: flex;
  padding: 0px 10px 12px 10px;
  align-items: center;
}
