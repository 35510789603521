.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}
@media screen and (max-width: 772px) {
  .container {
    height: calc(100vh - 336px - 48px);
    top: 48px;
  }
}
.item {
  width: 175px;
  overflow: visible;
}
