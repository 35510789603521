.buttonOuter {
  height: 80px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  border-width: 0;
}

.buttonInner {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex: 1;
}

.icon {
  padding: 5px 15px 5px 21px;
  align-self: center;
}

.textContainer {
  flex-direction: column;
  align-self: center;
}

.chevronHolder {
  align-self: center;
  padding-right: 4;
}

.titleText {
  font-size: 22px;
  font-weight: 600;
}

.detailText {
  font-size: 12px;
  font-weight: 600;
}
