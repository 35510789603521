.adyen-input {
  width: 100%;
  margin-bottom: 0;
  margin-right: 8px;
}
.adyen-input > label {
  display: block;
}
.adyen-input > label > span {
  box-sizing: border-box;
  font-size: 0.81em;
  color: #00112c;
  display: block;
  font-weight: 400;
  line-height: 13px;
  padding-bottom: 5px;
}
.adyen-input > label > span text {
  color: #00112c;
  display: block;
  font-size: 0.81em;
  font-weight: 400;
  line-height: 13px;
  padding-bottom: 5px;
  transition: color 0.1s ease-out;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.adyen-input > label > div {
  box-sizing: border-box;
  position: relative;
  display: block;
  transition: margin 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 0.3s ease-out;
  background: #f7f8f9;
  margin: 0 0 8px 0;
  border-radius: 12px;
  cursor: default;
}
.adyen-input > label > div > input {
  color: #00112c;
  font-weight: 100;
  font-size: 1em;
  line-height: 28px;
  caret-color: #06f;
  display: block;
  height: 40px;
  background: #fff;
  padding: 5px 8px;
  position: relative;
  outline: none;
  width: 100%;
  transition: border 0.2s ease-out, box-shadow 0.2s ease-out;
  margin: 0;
  border: 1px solid #b9c4c9;
  border-radius: 6px;
}
.adyen-input > label > div > input::placeholder,
.adyen-input > label > div > input:placeholder-shown {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #b9c4c9;
  font-weight: 200;
}
.adyen-input > label > div > input:active,
.adyen-input > label > div > input:focus {
  border: 1px solid #06f;
  box-shadow: 0 0 0 2px #99c2ff;
}
.adyen-input :focus-within > span {
  color: #06f;
}
