.grid {
  margin: 0 8px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: calc(100% - 16px);
}

.header {
  position: relative;
}

.deliveryWrapper {
  margin-top: 16.2px;
}

.pickUpWrapper {
  margin-top: 8px;
}

.homeScreenAboutPricing {
  margin-top: 15px;
}
