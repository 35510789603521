.title {
  font-size: 35px;
  font-weight: 600;
  line-height: 41px;
  margin: 6px 0;
}
.stickyButton {
  position: fixed;
  bottom: 20px;
  z-index: 1;
  padding: 0;
}
@media screen and (min-width: 566px) {
  .stickyButton {
    width: 540px;
  }
}
@media screen and (max-width: 565px) {
  .stickyButton {
    width: 100%;
    max-width: 358px;
  }
}
@media screen and (max-width: 365px) {
  .stickyButton {
    width: calc(100% - 16px);
  }
}
.omniBarContainer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 1;
  padding: 0;
}
:global(.kiosk-web) .omniBarContainer {
  width: 100%;
  left: 0;
}
@media screen and (min-width: 566px) {
  .omniBarContainer {
    width: 540px;
    left: unset;
  }
}
@media screen and (max-width: 565px) {
  .omniBarContainer {
    width: 100%;
  }
}
