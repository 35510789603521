.description {
  font-size: 15px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 5px;
  overflow-y: hidden;
}
:global(.kiosk-web) .description {
  font-size: 25px;
}
.productCard {
  z-index: 2;
}
.closed {
  max-height: 38px;
}
:global(.kiosk-web) .closed {
  max-height: 66px;
}
.more {
  font-size: 12px;
  margin: 0;
  color: #007aff;
  cursor: pointer;
  padding: 4px 0 4px 5px;
}
:global(.kiosk-web) .more {
  font-size: 19px;
}
.subtitle {
  margin-top: -14px;
  margin-left: 4px;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 300;
}
:global(.kiosk-web) .subtitle {
  font-size: 25px;
  margin-top: -5px;
}
