.controls {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 0;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 1.125rem;
  cursor: pointer;
  background-color: #1fc36a;
}
.button svg {
  height: 2rem;
  width: 2rem;
}
.disabled {
  color: #1fc36a;
  background-color: #e0e0e0;
}
.text {
  width: 2.5rem;
  font-size: 1.375rem;
  font-weight: 600;
  text-align: center;
  outline: none;
  border: none;
  margin: 0.25rem 0px;
  transition: width 0.1s;
  background-color: transparent;
}
@media screen and (max-width: 772px) {
  .controls {
    padding: 5px;
  }
  .button {
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 15px;
  }
  .button svg {
    height: 28px;
    width: 28px;
  }
  .text {
    width: 3.2rem;
  }
}
@supports (-webkit-overflow-scrolling: touch) {
  .button {
    padding: 2px 0 0 0;
  }
}
