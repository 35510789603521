.wrapper {
  text-align: center;

  .iconWrapper {
    margin: 16px 0 26px;
  }

  .textWrapper {
    padding: 0 25px;
    font-size: 15px;

    .title {
      font-weight: 700;
      margin: 0;
    }

    .subtitle {
      font-weight: 400;
      margin: 0 0 18px;
    }
  }

  .buttons {
    display: flex;

    > :nth-child(1n + 2) {
      margin-left: 10px;
    }
  }
}
