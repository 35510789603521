.modalContent {
  width: 550px;
  overflow: auto;
  grid-area: menu;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  overflow-y: auto;
  height: 100vh;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  :global(.kiosk-web) & {
    width: 100%;
    height: 100%;
  }

  @media screen {
    @media (max-width: 1139px) {
      width: 500px;
      padding-bottom: 17vh;
    }

    @media (max-width: 954px) {
      width: 350px;
      padding-bottom: 17vh;
    }

    @media (max-width: 772px) {
      width: 100%;
      height: 100%;
      border-radius: unset;
      padding-bottom: 17vh;
    }
  }
}
