.modalContent {
  width: 550px;
  overflow: auto;
  grid-area: menu;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  overflow-y: auto;
  height: 100vh;
}
.modalContent::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
:global(.kiosk-web) .modalContent {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1139px) {
  .modalContent {
    width: 500px;
    padding-bottom: 17vh;
  }
}
@media screen and (max-width: 954px) {
  .modalContent {
    width: 350px;
    padding-bottom: 17vh;
  }
}
@media screen and (max-width: 772px) {
  .modalContent {
    width: 100%;
    height: 100%;
    border-radius: unset;
    padding-bottom: 17vh;
  }
}
