.container {
  padding-bottom: 15px;
  margin: 0px 10px;

  > div {
    align-items: flex-start;
    padding: 10px 0;
  }
}

.optOutAllContainer {
  .container;
  column-gap: 10px;
  display: grid;
  grid-auto-flow: column;
}

.vipOffersTc {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  color: #000;
  .containerContextual & {
    margin-top: 20px;
  }
  a {
    color: rgb(0, 122, 255);
  }
}

.containerContextual {
  background-color: #fff;
  padding: 14px 8px 25px 8px;
  border-radius: 15px;
  margin: -75px 10px 0 10px;
  position: relative;
}

.subHeading {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  margin-bottom: 15px;
}

.hr {
  border: 1px solid #e3e3e5;
  border-top: none;
  margin: 10px 0;
}

.checkbox {
  padding-left: 10px;
}

.checkboxLabel {
  margin: 0;
}

.contextualCheckboxLabel {
  margin: 10px 0;
  font-size: 10px;
}
