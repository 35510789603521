@error-color: #e21836;
@field-height: 44px;
@focus-color: #2979ff;
@text-padding: 11px;
@background-color: rgba(0, 0, 0, 0.06);
@kiosk-field-height: 72px;
@kiosk-text-padding: 16px;
@kiosk-input-font-size: 25px;

.wrapper {
  display: inline-block;
  vertical-align: top;
}

.wrapperDisabled {
  opacity: 0.5;
}

.inputContainer {
  background-color: @background-color;
  border-radius: 4px;
  display: flex;
  height: @field-height;
  padding-left: @text-padding;
  padding-right: @text-padding;
  position: relative;
  :global(.kiosk-web) & {
    height: @kiosk-field-height;
    padding-left: @kiosk-text-padding;
    padding-right: @kiosk-text-padding;
  }
}

.inputContainerWithClearButton:extend(.inputContainer) {
  padding-right: 0;
}

.prefix {
  margin-top: 21px;
}

.placeholder {
  color: rgba(0, 0, 0, 0.54);
  left: @text-padding;
  position: absolute;
  top: @text-padding;
  transition: top 0.1s;
  :global(.kiosk-web) & {
    left: @kiosk-text-padding;
    top: @kiosk-text-padding;
    font-size: @kiosk-input-font-size;
  }
}

.placeholderHasValue:extend(.placeholder) {
  font-size: 12px;
  top: 7px;
  :global(.kiosk-web) & {
    font-size: 22px;
    left: @kiosk-text-padding;
    top: 11px;
  }
}

.placeholderHasFocus:extend(.placeholderHasValue) {
  color: @focus-color;
  :global(.kiosk-web) & {
    font-size: 22px;
    left: @kiosk-text-padding;
    top: 11px;
  }
}

.placeholderHasError:extend(.placeholderHasValue) {
  color: @error-color;
  :global(.kiosk-web) & {
    font-size: 22px;
    left: @kiosk-text-padding;
    top: 11px;
  }
}

.input {
  background-color: transparent;
  border: none;
  font-size: 15px;
  outline: none;
  padding-bottom: 7px;
  padding-top: 21px;
  width: 100%;
  z-index: 2;
  :global(.kiosk-web) & {
    font-size: @kiosk-input-font-size;
    padding-top: 50px;
    padding-bottom: 15px;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.inputHasFocus:extend(.input) {
  caret-color: @focus-color;
  :global(.kiosk-web) & {
    font-size: @kiosk-input-font-size;
    padding-top: 50px;
    padding-bottom: 15px;
  }
}

.inputHasError:extend(.input) {
  caret-color: @error-color;
  :global(.kiosk-web) & {
    font-size: 22px;
    padding-top: 50px;
    padding-bottom: 20px;
  }
}

.underline {
  border: 1px solid rgba(0, 0, 0, 0.54);
  height: 0px;
  position: relative;
  top: -2px;
  width: 100%;
}

.underlineHasFocus:extend(.underline) {
  border-color: @focus-color;
}

.underlineHasError:extend(.underline) {
  border-color: @error-color;
}

.errorContainer {
  color: @error-color;
  font-size: 11px;
  min-height: 14px;
  padding-left: @text-padding;
  padding-right: @text-padding;
  position: relative;
  top: -1px;
  :global(.kiosk-web) & {
    font-size: 22px;
  }
}

.spinner {
  margin-bottom: auto;
  margin-top: auto;
}

.clearButtonContainer {
  background-color: transparent;
  height: @field-height;
  float: left;
  margin-bottom: auto;
  margin-top: auto;
  padding-top: 3px;
  cursor: pointer;
}
