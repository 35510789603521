.cell {
  display: grid;
  padding: 11px;
  border-top: 1px solid rgba(200, 199, 204, 0.5);
}
.empty {
  color: #898989;
  text-align: center;
}
.record {
  padding: 13px;
  grid-template-rows: repeat(2, min-content);
  grid-template-columns: repeat(2, auto);
  grid-template-areas: 'status date' 'total points';
}
.record .status {
  grid-area: status;
  font-size: 17px;
  font-weight: 500;
}
.record .total {
  grid-area: total;
}
.record .points {
  grid-area: points;
}
.record .date {
  grid-area: date;
}
.record .details {
  grid-area: 'details';
}
.record .date,
.record .points {
  text-align: right;
}
.record .price,
.record .points {
  font-weight: 700;
}
.record .date,
.record .details {
  color: #8f8e94;
}
