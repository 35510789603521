@import '../../../hooks-and-hocs/theme/theme-data/standard-theme.less';

.container {
  background-color: transparent;
  position: sticky;
  top: 3.5vh;
  align-self: center;
  z-index: 4;
  box-shadow: @shadow[light];
  svg {
    width: 12rem;
    height: 3rem;
  }
}
