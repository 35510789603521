@import '../../../hooks-and-hocs/theme/theme-data/standard-theme.less';

.container {
  border-radius: 50px;
  display: flex;
  flex: 1;
  cursor: pointer;

  &.backBackground {
    box-shadow: @shadow[light];
    background-color: rgba(0, 0, 0, 0.45);
  }

  &.notInNavBar {
    position: absolute;
    top: 6.5rem;
  }
}

.text {
  height: 19px;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.02px;
  padding-left: 4px;
  margin: 0;
  line-height: 19px;
  color: @standardColors[action-text-blue];

  :global(.kiosk-web) & {
    height: 32px;
    font-size: 30px;
    line-height: normal;
    letter-spacing: 0;
    margin-bottom: 5px;
  }

  .backBackground & {
    color: #fff;
  }
}

.innerContainer {
  padding-left: 10px;
  padding-right: 14px;
  display: flex;
  align-items: center;
  height: 33px;

  svg {
    :global(.kiosk-web) & {
      width: 30px;
      height: 20px;
    }

    polygon {
      fill: @standardColors[action-text-blue];

      .backBackground & {
        fill: #fff;
      }
    }
  }
}
