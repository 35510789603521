.container {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.createAction {
  text-align: center;
  border: 1px solid #c8c7cc66;
  border-radius: 12px;
  width: 48%;
  margin: 1%;
  padding: 2%;

  &:hover {
    cursor: pointer;
  }

  .imageQuattro {
    background-image: url('../../../../res/assets/svgs/product-build-your-own-icons/create-quattro.svg') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: inherit;
    padding: 25%;
    display: flex;
  }

  .imageHalfHalf {
    background-image: url('../../../../res/assets/svgs/product-build-your-own-icons/create-half-half.svg') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: inherit;
    padding: 25%;
    display: flex;
  }
}
