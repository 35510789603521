.changeOrderButton {
  background-color: #d6e4fd;
  border: none;
  color: #3478f6;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  margin: 4px 2px;
  border-radius: 8px;
  font-weight: 600;
  height: 40px;
  cursor: pointer;
}
.changeOrderButtonContainer {
  display: flex;
  flex-direction: column;
  padding-top: 3px;
}
.changeIcon {
  vertical-align: center;
}
.wrapper {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  color: #3478f6;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  width: 15.5rem;
}
.wrapper.expanded {
  white-space: pre-wrap;
}
.wrapper.collapsed {
  white-space: nowrap;
}
.wrapperExpressCheckout {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  color: #3478f6;
  font-weight: 590;
  font-size: 12px;
  line-height: normal;
}
.headerIcon {
  display: block;
  float: left;
  margin-right: 5px;
}
.headerIcon svg {
  margin-top: 3px;
}
.title {
  font-weight: 600;
  font-size: 14px;
}
.titleExpressCheckout {
  font-weight: 590;
  font-size: 12px;
}
.header {
  cursor: pointer;
  width: 100%;
  position: relative;
  color: #020202;
}
.header .orderHumanReadableDateTime {
  padding-left: 5px;
  color: #3478f6;
}
.headerWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.noncollapsibleContent {
  display: flex;
  flex-direction: row;
}
