.titledCardContainer {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}
:global(.kiosk-web) .titledCardContainer {
  max-width: unset;
  width: 650px;
}
.basketSceneContainer > div > div {
  margin-top: 0 !important;
}
