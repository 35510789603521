.title {
  font-weight: bold;
  margin-bottom: 4px;
}
.column:first-child {
  padding: 0 28px 19px 0;
}
@media screen and (min-width: 360px) {
  .column:first-child {
    padding: 0 58px 19px 0;
  }
}
@media screen and (min-width: 773px) {
  .column:first-child {
    padding: 0 40px 0 0;
  }
}
@media screen and (min-width: 773px) {
  .column {
    padding: 0 40px 0 0;
  }
}
