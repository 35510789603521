.iframeWrapper {
  border-top: 1px solid #c8c7cc;
  padding-top: 55%;
  position: relative;
}
@media screen and (max-width: 772px) {
  .iframeWrapper {
    padding-top: 65%;
  }
}
@media screen and (max-width: 565px) {
  .iframeWrapper {
    padding-top: 95%;
  }
}
.iframeWrapper iframe {
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
