.wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
@supports (display: grid) {
  .wrapper {
    display: grid;
    grid-template-columns: [menu] minmax(358px, 915px) [gap] minmax(8px, 60px) [sidebar] 385px;
    grid-template-areas: 'menu . sidebar';
  }
}
.stickyWrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 98px;
  width: 360px;
}
.staticWrapper {
  position: -webkit-static;
  position: static;
  margin: 8px 8px 0 0;
  width: 360px;
}
.withoutSidebarWrapper {
  display: flex;
  justify-content: center;
  padding: 10px 8px 8px;
}
@supports (display: grid) {
  .withoutSidebarWrapper {
    display: grid;
    grid-template-columns: [menu] 100%;
    grid-template-areas: 'menu';
  }
}
.sidebarWrapper {
  position: relative;
  margin-left: 54px;
}
@supports (display: grid) {
  .sidebarWrapper {
    grid-area: sidebar;
    margin-left: 0px;
  }
}
.withSidebar {
  max-width: 915px;
  position: relative;
  width: 100%;
}
@supports (display: grid) {
  .withSidebar {
    padding: 8px 4px 8px 4px;
    justify-self: center;
  }
}
@media screen and (max-width: 1377px) {
  .sidebarWrapper {
    margin-left: unset;
  }
  @supports (display: grid) {
    .sidebarWrapper {
      margin-left: 8px;
    }
  }
  @supports (display: grid) {
    .wrapper {
      grid-template-columns: [menu] minmax(358px, 915px) [sidebar] 391px;
      grid-template-areas: 'menu sidebar';
    }
  }
}
@media screen and (max-width: 1322px) {
  .withSidebar {
    max-width: 732px;
  }
}
@media screen and (max-width: 1139px) {
  .withSidebar {
    max-width: 549px;
  }
  :global(.kiosk-web) .withSidebar {
    max-width: 660px;
  }
}
@media screen and (min-width: 772px) and (max-width: 980px) {
  .withSidebar {
    max-width: 100%;
  }
  @supports (display: grid) {
    .withSidebar {
      margin-left: 8px;
    }
  }
}
.withoutSidebar {
  max-width: 100%;
}
@supports (display: grid) {
  .withoutSidebar {
    justify-self: center;
  }
}
:global(.kiosk-web) .withoutSidebar {
  width: 100%;
  padding: 0 40px;
}
.withoutSidebar.leftNavMenu {
  max-width: 85vw;
  justify-self: right;
}
@media screen and (max-width: 772px) {
  @supports (display: grid) {
    .wrapper {
      display: grid;
      grid-template-columns: [menu] 100%;
      grid-template-areas: 'menu';
    }
  }
  .withoutSidebar {
    max-width: 550px;
    min-width: 358px;
  }
}
@media screen and (max-width: 565px) {
  .withoutSidebar {
    max-width: 358px;
  }
}
@media screen and (max-width: 380px) {
  .withoutSidebar {
    max-width: 366px;
  }
}
@media screen and (max-width: 365px) {
  .withoutSidebar {
    padding: 0 2px;
    max-width: 359px;
  }
}
@media screen and (max-width: 357px) {
  .withoutSidebar {
    padding: 0 8px;
    width: 100%;
    min-width: initial;
  }
}
