.title {
  font-size: 35px;
  font-weight: 600;
  line-height: 41px;
  margin: 6px 0;
}

.disclaimerContainer {
  display: inline-block;
}

.disclaimer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 12px;
  border: 2px solid #ff5c47;
  border-radius: 8px;
  color: #ff5c47;
}

.disclaimer a {
  opacity: 0.9;
  color: #ff5c47;
}

.disclaimer a:hover {
  color: #ff5c47;
}

.description {
  flex: 1;
  margin: 0 0 0 4px;
}
