.dropDownItem {
  border-bottom: 1px solid rgba(200, 199, 204, 0.5);
  min-height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.dropDownItem:hover {
  background-color: #efeff4;
}
.dropDownItemText {
  padding-left: 11px;
  padding-right: 11px;
}
.dropDownItemIcon {
  padding-left: 11px;
  padding-top: 3px;
}
