.container {
  font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2px;
}
:global(.kiosk-web) .container {
  font-size: 25px;
}
.details {
  display: flex;
  flex-direction: row;
  padding-right: 4px;
  align-items: center;
}
.details .label {
  font-weight: 600;
  margin: 0px 4px 0px 0px;
}
.details .description {
  font-weight: 300;
}
.details .popupButton {
  font-size: 0.9em;
  cursor: pointer;
  display: flex;
}
.details .popupButton:hover {
  text-decoration: underline;
}
.price {
  font-weight: 600;
}
