.withoutSidebarWrapper {
  display: flex;
  justify-content: center;
  margin-top: 4px;
}
@supports (display: grid) {
  .withoutSidebarWrapper {
    display: grid;
    grid-template-columns: [menu] 100%;
    grid-template-areas: 'menu';
  }
}
.withoutSidebar {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}
@supports (display: grid) {
  .withoutSidebar {
    margin-top: 4px;
    display: grid;
    grid-gap: 8px;
    grid-area: menu;
    grid-template-columns: repeat(auto-fill, minmax(175px, 0fr));
    justify-self: center;
    width: 100%;
  }
}
@supports (display: grid) {
  :global(.kiosk-web) .withoutSidebar {
    grid-template-columns: repeat(auto-fill, minmax(322px, 0fr));
  }
  :global(.kiosk-web) .withoutSidebar.leftNavMenu {
    grid-template-columns: repeat(auto-fill, minmax(274px, 0fr));
  }
}
@media screen and (max-width: 772px) {
  .withoutSidebar {
    max-width: 550px;
  }
}
@media screen and (max-width: 565px) {
  .withoutSidebar {
    max-width: 370px;
  }
}
@media screen and (max-width: 380px) {
  .withoutSidebar {
    max-width: 366px;
  }
}
@media screen and (max-width: 365px) {
  .withoutSidebar {
    max-width: 359px;
  }
  @supports (display: grid) {
    .withoutSidebar {
      grid-gap: 4px;
    }
  }
}
@media screen and (max-width: 357px) {
  .withoutSidebar {
    max-width: 175px;
  }
}
