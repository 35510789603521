@import '../../../../hooks-and-hocs/theme/theme-data/standard-theme.less';

.popupBackground {
  opacity: 0;
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}
.image {
  border-radius: 15px;
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.closeIconContainer {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 11px;
}
.buttons {
  position: absolute;
  width: 100%;
  bottom: 18px;
}
.buttonsContainer {
  display: flex;
  flex-direction: column;
  margin: 0 11px;
  flex: 1;
}

.popupContent {
  width: 390px;
  height: 740px;
  position: relative;
  align-self: center;

  @media screen {
    @media (max-width: 400px) {
      width: 350px;
      height: 665px;
    }
    @media (max-width: 355px) {
      width: 300px;
      height: 670px;
    }
  }
}

.yesButtonContainer,
.noButtonContainer {
  flex: 1;
  border-radius: 8;
  margin: 5px;
  margin-top: 11px;
  margin-bottom: 11px;
  div {
    font-size: 17px !important;
    font-weight: 600 !important;
    text-align: center;
  }
}

.noButtonContainer {
  background-color: @standardColors[grey] !important;
}
