.title {
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02;
  color: #000000;
  margin: 0;
  font-size: 22px;
  max-width: 220px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.subtitle {
  height: 19px;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0;
  color: #4a4a4a;
  margin: 0;
}
.accountContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.accountContainer svg {
  width: 26px;
  height: 24px;
}
.textContainer {
  padding-left: 10px;
}
@media only screen and (max-width: 772px) {
  .title {
    font-size: 16px;
    max-width: 120px;
  }
  .textContainer {
    padding-left: 6px;
  }
  .subtitle {
    display: none;
  }
}
