.button {
  width: 100%;
  height: 54px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 10px 0;
  cursor: pointer;
}

.buttonLabel {
  color: #ffffff;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  cursor: inherit;
}

.signUpMessage {
  margin: 8px 8px 0 0;
  [role='button'] {
    padding: 0 0 0 4px;
    cursor: pointer;
    color: #007aff;
  }
}
