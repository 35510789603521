.iconWrapper {
  text-align: center;
  padding: 28px 0;
}
:global(.kiosk-web) .iconWrapper {
  padding: 56px 0;
}
.iconStyle {
  width: 80%;
}
:global(.kiosk-web) .iconStyle {
  height: 267px;
}
