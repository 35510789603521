.cardContainer {
  box-shadow: 0px 0.5px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  background-color: white;
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.contentContainer {
  width: 400px;
  max-width: 95vw;
  min-height: 250px;
  max-height: 90vh;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.closeButton {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  top: 7px;
  right: 6px;
  position: absolute;
  cursor: pointer;
  background-color: #4a4a4a;
}

.contentHeader {
  margin: 0 0 10px 0;
  font-size: 20px;
}

.contentBody {
  text-align: left;

  img {
    width: 100%;
    margin-top: 10px;
  }
}

.contentFooter {
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;

  > div:first-child {
    font-weight: bold;
  }

  button {
    margin-top: 10px;

    @media screen and (max-width: 420px) {
      > div:first-child {
        font-size: 14px !important;
      }
    }
  }
}
