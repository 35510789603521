.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.messageContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  color: #4a4a4a;
}
.messageContainer .notified {
  display: flex;
  flex-direction: column;
}
.messageContainer .notifiedMessage {
  color: #1fc36a;
}
.messageContainer p {
  margin: 0;
  padding-left: 4px;
}
.messageContainer .title {
  text-transform: uppercase;
}
