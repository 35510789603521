.deliveryAddressMapViewWrapper {
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  min-height: 584px;
}
.addressSearchContainer {
  position: absolute;
  top: 8px;
  left: calc(50% - 180px);
  z-index: 3;
}
.mapProvider {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
}
.confirmAddressContainer,
.confirmAddressContainerMap,
.confirmAddressContainerList,
.confirmAddressContainerListError,
.confirmAddressContainerMapError {
  position: absolute;
  left: calc(50% - 180px);
  z-index: 3;
  width: 360px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5px 10px 0px;
  border-radius: 15px;
  background-color: white;
}
.confirmAddressContainerMap {
  bottom: 8px;
  margin-top: 0px;
}
.confirmAddressContainerList {
  top: 8px;
}
.confirmAddressContainerListError {
  top: 126px;
}
.confirmAddressContainerMapError {
  bottom: 8px;
  margin-top: 0px;
}
