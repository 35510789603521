.wrapper {
  text-align: center;
}
.wrapper .iconWrapper {
  margin: 16px 0 26px;
}
.wrapper .textWrapper {
  padding: 0 25px;
  font-size: 15px;
}
.wrapper .textWrapper .title {
  font-weight: 700;
  margin: 0;
}
.wrapper .textWrapper .subtitle {
  font-weight: 400;
  margin: 0 0 18px;
}
.wrapper .buttons {
  display: flex;
}
.wrapper .buttons > :nth-child(1n + 2) {
  margin-left: 10px;
}
