.headerContainer {
  padding: 8px 8px 0 8px;
}

.headerPrice {
  font-size: 15px;
}

.headerTitle {
  font-size: 28px;
  font-weight: 600;
}

.headerDetailBox {
  margin-bottom: 10px;
}

.halvesContainer {
  display: flex;
  padding: 10px;
  text-align: center;
}

.halfLeftContainer {
  margin-right: 10px;
  flex: 1;
}

.halfRightContainer {
  flex: 1;
}
