@media screen and (max-width: 365px) {
  .card {
    margin: 4px 2px;
  }
  @supports (display: grid) {
    .card {
      margin: 0;
    }
  }
}
.minimalistAdd {
  font-size: 14px;
  font-weight: 600;
  display: -webkit-box;
  flex-direction: row;
  color: #000000;
}
.minimalistVoucherPriceGreen {
  font-size: 14px;
  font-weight: 600;
  color: #1fc36a;
  padding-left: 8px;
}
.minimalistVoucherPriceGray {
  font-size: 14px;
  font-weight: 600;
  color: #6e6e6e;
  padding-left: 8px;
}
.imageContainer {
  position: relative;
}
.card {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 4px;
  width: 174px;
}
@supports (display: grid) {
  .card {
    margin: 0;
  }
}
:global(.kiosk-web) .card {
  width: 322px;
}
:global(.kiosk-web) .card.leftNavMenu {
  width: 274px;
}
.image {
  width: 100%;
  object-fit: cover;
  height: 150px;
}
:global(.kiosk-web) .image {
  height: 264px;
}
:global(.kiosk-web) .leftNavMenu .image {
  height: 230px;
}
.priceDetailContainer {
  font-weight: 300;
  margin: 0;
  padding: 8px;
  font-size: 14px;
}
:global(.kiosk-web) .priceDetailContainer {
  font-size: 25px;
}
.description {
  overflow-y: hidden;
  margin: 0;
  padding-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-height: 17px;
  font-size: 12px;
  max-height: 58px;
}
.description sup {
  vertical-align: top;
  font-size: 0.6em;
  top: -0.5em;
  font-weight: 700;
  position: relative;
}
:global(.kiosk-web) .description {
  line-height: 30px;
  font-size: 21px;
  max-height: 102px;
}
.title {
  margin: 8px;
  overflow-y: hidden;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 17px;
  font-size: 15px;
  max-height: 44px;
}
:global(.kiosk-web) .title {
  line-height: 30px;
  font-size: 26px;
  max-height: 62px;
}
.separator {
  border-bottom: 0.1rem solid rgba(200, 199, 204, 0.5);
}
.legends {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0.2rem;
  bottom: 0.2rem;
}
.legends img {
  width: 1.375rem;
}
.energyInfoContainer {
  font-weight: 300;
}
