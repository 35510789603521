.trackerBar {
  display: flex;
  overflow: hidden;
}

.trackerSlider {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
}
