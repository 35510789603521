.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.name {
  font-size: 15px;
  text-align: center;
  line-height: 15px;
  margin: 0;
  :global(.kiosk-web) & {
    font-size: 25px;
  }
}

.price {
  font-size: 15px;
  text-align: center;
  line-height: 20px;
  font-weight: 600;
  margin: 0;
  :global(.kiosk-web) & {
    font-size: 25px;
    line-height: 50px;
  }
}

.iconContainer {
  justify-content: center;
  align-items: center;
}

.icon {
  border: 3px solid;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  object-fit: cover;
}
