.orderTracker {
  background: white;
  margin: 8px 0;
  border-radius: 0 0 17.5px 17.5px;
  overflow: hidden;
}
.orderTrackerTitle {
  margin: 0;
  padding: 14.6px 8px;
}
@keyframes placeholderShimmer {
  0% {
    background-position: -1600px 0;
  }
  100% {
    background-position: 1600px 0;
  }
}
.placeholderTracker {
  height: 200px;
  width: 100%;
  overflow: hidden;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #4a4a4a 8%, #363636 18%, #4a4a4a 33%);
  background-size: 1600px 210px;
  text-indent: -10000px;
}
