.subheading {
  text-transform: uppercase;
  font-weight: bold;
  color: #4a4a4a;
  letter-spacing: 0.25px;
  font-size: 15px;
}

.errorMessage {
  color: #e21836;
  font-size: 14px;
  font-weight: 600;
}

.recaptchaPolicy {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  color: #4a4a4a;
}

.switchContainer {
  display: flex;
  justify-content: flex-end;

  &.switchWrapper {
    padding-left: 40px;
  }
  input {
    -moz-appearance: initial;
  }
}

.switchLabel {
  font-size: 12px;
  font-weight: 700;
  padding-right: 8px;
  text-transform: uppercase;
}
