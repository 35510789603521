.wrapper {
  margin-top: 8px;
}
:global(.kiosk-web) .wrapper {
  margin-top: 40px;
}
.stickyButton {
  position: fixed;
  bottom: 20px;
  z-index: 1;
  width: inherit;
  max-width: inherit;
  padding-right: 16px;
}
.omniBarContainer {
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  left: 0;
}
:global(.kiosk-web) .addVoucherButton {
  padding: 40px !important;
  border-radius: 16px !important;
}
:global(.kiosk-web) .addVoucherButton div {
  font-size: 30px !important;
}
