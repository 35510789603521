.wrapper {
  display: block;
  min-width: 360px;
  text-align: left;
}
.mapListButton {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #dddde0;
  opacity: 1;
  background-color: #f4f4f5;
  cursor: pointer;
  text-align: center;
  padding-top: 1px;
}
.storedListWrapper {
  margin: 8px 0px 0px 0px;
  display: flex;
  justify-content: center;
}
