.bellContainer {
  position: relative;
  right: 15px;
  bottom: 19px;
}
.bellContainer svg {
  width: 10px !important;
  height: 10px !important;
}
.bellContainer svg path {
  fill: #fff;
}
.countContainer {
  display: contents;
}
