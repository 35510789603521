.cell {
  display: grid;
  padding: 11px;
  border-top: 1px solid rgba(200, 199, 204, 0.5);
}

.empty {
  color: #898989;
  text-align: center;
}

.record {
  padding: 13px;
  grid-template-rows: repeat(2, min-content);
  grid-template-columns: repeat(2, auto);
  grid-template-areas: 'status date' 'total points';

  .status {
    grid-area: status;
    font-size: 17px;
    font-weight: 500;
  }

  .total {
    grid-area: total;
  }

  .points {
    grid-area: points;
  }

  .date {
    grid-area: date;
  }

  .details {
    grid-area: 'details';
  }

  .date,
  .points {
    text-align: right;
  }

  .price,
  .points {
    font-weight: 700;
  }

  .date,
  .details {
    color: #8f8e94;
  }
}
