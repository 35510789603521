.container {
  background-color: #efeff4;
  border-radius: 8px;
}
.container > div {
  border-bottom: 1px solid #c8c7cc;
}
.container > div:last-child {
  border-bottom: none;
}
.header {
  padding: 12px;
}
.header .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header .label {
  font-size: 17px;
  font-weight: 600;
}
.header .name {
  font-size: 15px;
  font-weight: 400;
}
