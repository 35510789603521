.container {
  align-items: center;
  user-select: none;
  padding: 10px;
  padding-left: 11px;
}
.h3 {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 19px;
}
.buttonWrapper {
  margin-bottom: 10px;
}
.preOrderETAContainer {
  margin-bottom: 13px;
}
