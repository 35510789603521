.container {
  padding: 10px;
  border-radius: 19px 19px 0px 0px;
  display: flex;
}
.container.longTextContainer:not(:global(.kiosk-web)) {
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: 772px) {
  .container {
    background-color: #fff;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.203371);
  }
}
:global(.kiosk-web) .container {
  background-color: #fff;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.203371);
  padding: 20px;
  flex-direction: row !important;
  margin-bottom: 0 !important;
}
:global(.kiosk-web) .container > *:not(:last-child) {
  margin-right: 10px !important;
}
.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 14px;
  white-space: nowrap;
  height: 100%;
  align-items: center;
  font-weight: 600;
}
:global(.kiosk-web) .button > div {
  font-size: 30px;
}
.placeOrderButton,
.skipButton {
  height: 38px;
  flex: 1;
  width: 150px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.placeOrderButton.longText:not(:global(.kiosk-web)),
.skipButton.longText:not(:global(.kiosk-web)) {
  margin-right: 0;
  margin-bottom: 8px;
  width: 98%;
}
@media screen and (min-width: 425px) {
  .placeOrderButton.longText:not(:global(.kiosk-web)),
  .skipButton.longText:not(:global(.kiosk-web)) {
    width: 400px;
  }
}
.placeOrderButton.skipButton,
.skipButton.skipButton {
  background-color: rgba(0, 122, 255, 0.2) !important;
  color: #007aff;
}
.placeOrderButton.placeOrderContainer,
.skipButton.placeOrderContainer {
  background-color: #007aff !important;
  color: #ffffff !important;
}
.placeOrderButton.disabled,
.skipButton.disabled {
  background-color: rgba(0, 122, 255, 0.2);
  color: #4a4a4a;
}
:global(.kiosk-web) .placeOrderButton,
:global(.kiosk-web) .skipButton {
  height: 98px !important;
  border-radius: 16px !important;
}
