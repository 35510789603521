.nutritionalLine {
  display: flex;
  width: 100%;
}
.nutritionalName {
  display: inline;
  padding-left: 5px;
  flex: 1;
}
.nutritionalValue {
  display: inline;
  text-align: right;
  justify-self: flex-end;
  padding-right: 3px;
}
.nutritionalUnit {
  display: inline;
  padding-right: 5px;
  text-align: right;
  justify-self: flex-end;
}
