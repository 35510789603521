.subscriptions {
  padding: 0 8px 8px;
  border-bottom: 1px solid rgba(200, 199, 204, 0.5);
  margin-bottom: 10px;
}
.checkbox {
  margin-bottom: 6px;
}
.checkboxText {
  font-size: 12px;
  font-weight: bold;
  color: #4a4a4a;
  letter-spacing: 0.1125px;
  text-transform: uppercase;
  margin: 0;
}
