.storeTradeDetailsContainer {
  display: grid;
  grid-template-columns: min-content auto auto;
  column-gap: 8px;
  row-gap: 10px;
  font-size: 15px;
  margin-bottom: 10px;
}
.storeTradeDetailsContainer .storeTradeDetailsName {
  font-weight: bold;
}
.storeTradeDetailsContainer .storeTradeDetailsValue {
  color: #4a4a4a;
  font-size: 14px;
}
@media screen and (max-width: 772px) {
  .storeTradeDetailsContainer {
    grid-template-columns: min-content auto;
    row-gap: 4px;
  }
  .storeTradeDetailsContainer .storeTradeDetailsValue {
    grid-column: 2;
  }
  .storeTradeDetailsContainer .storeTradeDetailsValue:has(+ svg) {
    margin-bottom: 4px;
  }
}
