.container {
  display: flex;
  align-items: center;
  width: 100%;
  height: inherit;
  position: relative;
  // Fix for footer overlap issue
  margin-bottom: 140px;
}

.textContainer {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
}

.textWelcome {
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  color: white;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0;
  text-align: center;
  margin-block-end: 0;
  text-transform: uppercase;
}

.textName {
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  color: white;
  font-weight: 900;
  font-size: 50px;
  text-transform: uppercase;
  margin-block-start: 0;
  text-align: center;
}

.horizontalRule {
  border: 1px solid #fff;
  border-top: none;
  border-left: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  width: 155px;
  margin-top: 0;
  margin-bottom: 0;
}
