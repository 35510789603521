.wrapper {
  display: block;
  min-width: 360px;
}

.mapListButton {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid rgba(221, 221, 224, 1);
  opacity: 1;
  background-color: rgba(244, 244, 245, 1);
  cursor: pointer;
  text-align: center;
  padding-top: 1px;
}

.storedListWrapper {
  margin: 8px 0px 0px 0px;
  display: flex;
  justify-content: center;
}
