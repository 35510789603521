.container {
  width: 600px;
  height: 70px;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  z-index: 4;
  align-self: center;
}
:global(.kiosk-web) .container {
  width: 100%;
  height: 120px;
}
:global(.kiosk-web) .container div {
  font-size: 27px !important;
}
@media screen and (max-width: 1139px) {
  .container {
    width: 550px;
  }
}
@media screen and (max-width: 954px) {
  .container {
    width: 400px;
  }
}
@media screen and (max-width: 772px) {
  .container {
    background-color: rgba(249, 249, 249, 0.9);
    width: 100%;
    padding-bottom: 16px;
  }
}
