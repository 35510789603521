.wrapper {
  padding: 35px 5px 10px 0px;
}
.contentWrapper {
  padding: 20px;
}
.itemCode {
  font-size: 12px;
  font-weight: bold;
  margin: 10px 0 0 0;
}
:global(.kiosk-web) .itemCode {
  font-size: 18px;
}
.itemText {
  font-size: 12px;
  margin: 0;
}
:global(.kiosk-web) .itemText {
  font-size: 18px;
  text-align: justify;
}
