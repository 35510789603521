.dropdown > ul {
  position: absolute;
  overflow: hidden;
  right: 0;
  margin: 0;
  margin-top: 0.75em;
  margin-right: 0.75em;
  border-radius: 15px;
  padding-left: 0;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown > ul > li {
  text-decoration: none;
  display: block;
  border-bottom: 1px solid #c8c7cc;
  transition: background-color 180ms;
  background-color: transparent;
}
.dropdown > ul > li:hover {
  background-color: #efeff4;
}
.dropdown > ul > li span {
  padding: 0.75em 6em 0.75em 1em;
}
.dropdown > ul > li:last-child {
  border-bottom: none;
}
.dropdown > button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
