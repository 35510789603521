.titledCardContainer {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
}

.loadingIcon {
  text-align: center;
  padding-top: 30px;
}

.description {
  white-space: pre-line;
  margin-top: 30px;
}
