.drop-down-item {
  color: black;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.drop-down-item > svg {
  padding-right: 10px;
  width: 26px;
  height: 24px;
}
.list ul {
  top: 88px;
}
@media only screen and (max-width: 772px) {
  .list ul {
    top: 48px;
  }
}
