.center {
  margin: 0 auto;
  max-width: 100%;
}

@media screen and (min-width: 773px) {
  .center {
    max-width: 732px;
  }
}

@media screen and (max-width: 772px) {
  .center {
    max-width: 550px;
  }
}

@media screen and (max-width: 565px) {
  .center {
    max-width: 370px;
  }
}

@media screen and (max-width: 380px) {
  .center {
    max-width: 366px;
  }
}

@media screen and (max-width: 365px) {
  .center {
    max-width: 359px;
    width: 100%;

    @supports (display: grid) {
      grid-gap: 4px;
    }
  }
}
