.close {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  top: 5px;
  right: 4px;
  position: absolute;
  cursor: pointer;
  background-color: #4a4a4a;
}
:global(.kiosk-web) .close {
  width: 30px;
  height: 30px;
  text-align: center;
}
:global(.kiosk-web) .closeIcon {
  margin-top: -6px;
}
