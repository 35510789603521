.image {
  width: 100%;
  height: 149px;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
:global(.kiosk-web) .image {
  height: 298px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.portionWrapper {
  position: relative;
}
.iconWrapper {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0.3rem;
  bottom: 0.5rem;
  width: 23px;
  height: 23px;
}
:global(.kiosk-web) .iconWrapper {
  width: 46px;
  height: 46px;
}
.portionType {
  width: 1.375rem;
  background-repeat: no-repeat !important;
}
