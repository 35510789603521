.wrapper {
  cursor: pointer;
}

.container {
  display: flex;
  justify-content: space-between;
  padding: 19px 11px 19px 0;
  border-top: 0.6px solid #a3a3a3;
}

.smallContainer {
  &:extend(.container);
  padding: 11px 11px 11px 0;
}

.removeBorderOnFirstItem {
  .wrapper {
    &:first-child {
      .container,
      .smallContainer {
        border-top: 0;
      }
    }
  }
}

.addBorderOnLastItem {
  .wrapper {
    &:last-child {
      .container,
      .smallContainer {
        border-bottom: 0.6px solid #a3a3a3;
      }
    }
  }
}

.textContainer {
  flex: 1 2 auto;
  margin-right: 8px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.36px;
  margin: 0;
}

.listTitle {
  &:extend(.title);
  padding-left: 8px;
  padding-top: 8px;
}

.highlightTitle {
  &:extend(.title);
  color: #007aff;
}

.subtitle {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.2px;
  margin: 0;
}

.storeDetailsDistance {
  font-size: 16px;
  font-weight: 600;
  text-align: right;
  margin: 0;
  white-space: nowrap;
}

.iconContainer {
  padding-top: 8px;
  padding-right: 8px;
}

.suffix {
  display: flex;
  align-items: center;
}
