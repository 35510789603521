.popupWarningIcon {
  margin-top: 15px;
}
.contentBody {
  margin: 30px 16px;
}
.section {
  display: grid;
  padding: 11px;
  grid-gap: 11px;
  text-align: center;
  max-width: 390px;
}
