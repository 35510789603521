.image {
  width: 100%;
  position: relative;
  object-fit: contain;
  z-index: 1;
}
:global(.kiosk-web) .image {
  top: 0;
}
@media screen and (max-width: 772px) {
  .image {
    top: 0;
    position: fixed;
  }
}
