@import '../../hooks-and-hocs/theme/theme-data/standard-theme.less';

.container {
  background-color: #fff;
  padding: 10px;
  border-radius: 19px 19px 0px 0px;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.203371);
  display: flex;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 17px;
  white-space: nowrap;
  height: 100%;
  align-items: center;
  font-weight: 600;
  color: @standardColors[button-text-default];

  :global(.kiosk-web) & {
    > div {
      font-size: 30px;
    }

    svg {
      width: 35px;
      height: 28px;
    }
  }
}

.countBadge {
  background-color: #cf343d;
  padding: 10px 6px;
  border-radius: 15px;
  margin: 0px 9px 0px 2.5px;
  font-size: 15px;

  :global(.kiosk-web) & {
    padding: 15px 8px;
    margin: 0px 10px 0px 8px;
  }
}

.cartButton {
  height: 50px;
  margin-right: 10px;
  background-color: @standardColors[button-background-blue] !important;
  flex: 1;

  :global(.kiosk-web) & {
    height: 100px;
  }
}

.placeOrderButton {
  height: 50px;
  background-color: @standardColors[action-standard];
  flex: 1;

  :global(.kiosk-web) & {
    height: 100px;
  }
}
