.image {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  max-width: 395px;
}
.cardContainer {
  box-shadow: 0px 0.5px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  background-color: white;
  z-index: 1;
  position: relative;
  overflow: hidden;
}
.buttonBar {
  padding: 0px 6px;
  display: flex;
  justify-content: center;
}
.close {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  top: 7px;
  right: 6px;
  position: absolute;
  cursor: pointer;
  background-color: #4a4a4a;
}
:global(.kiosk-web) .close {
  width: 30px;
  height: 30px;
  text-align: center;
}
:global(.kiosk-web) .closeIcon {
  margin-top: -6px;
}
.offerContainer {
  padding: 0px 0 6px 0;
}
@media screen and (max-width: 430px) {
  .image {
    width: 360px;
  }
}
