@import '../../../hooks-and-hocs/theme/theme-data/standard-theme.less';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 17px;
  white-space: nowrap;
  color: #ffffff;
  height: 100%;
  align-items: center;
  font-weight: 600;

  :global(.kiosk-web) & {
    font-size: 27px;
  }

  :disabled > & {
    color: @standardColors[grey];
  }

  > * {
    margin: 0px 7px;

    &:first-child {
      margin-left: 0;
      flex: 1;
      text-align: left;
    }

    &:last-child {
      margin-right: 0px;
      flex: none;
    }
  }
}

.button {
  height: 54px;
  background-color: @standardColors[action-standard];
  :global(.kiosk-web) & {
    height: 80px;
  }

  &.fill {
    width: 100%;
  }

  &:disabled {
    background-color: @standardColors[button-background-disabled] !important;
  }
}
