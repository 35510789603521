@menu-bg-colour: #4a4a4a;
@bg-colour: white;

.wrapper {
  display: flex;
  flex-direction: column;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 4;
}

.mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: @bg-colour;
  height: 48px;
  padding: 0 8px;

  > .logo {
    flex: 1;
    text-align: center;
  }

  .back {
    display: flex;
    align-items: center;
    color: @menu-bg-colour;
    font-size: 16px;
    font-weight: 600;
    flex: 1;
    cursor: pointer;

    > span {
      margin-left: 4px;
    }
  }

  > .empty {
    display: flex;
    align-self: stretch;
    align-items: center;
    flex: 1;
  }
  > .resetButton {
    display: flex;
    flex: 1;
    margin-right: 2%;
    justify-content: right;
  }
}

.group:before,
.group:after {
  content: '';
  display: table;
}
.group:after {
  clear: both;
}
.group {
  zoom: 1;
  padding: 0;
}

.tabs {
  list-style: none;
  margin: 0;
  align-self: flex-end;
  // width: 860px;
}
.tabs li {
  /* Makes a horizontal row */
  float: left;

  /* So the psueudo elements can be
     abs. positioned inside */
  position: relative;
  margin-right: 10px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 16px;
}
.tabs a {
  /* Make them block level
     and only as wide as they need */
  float: left;
  padding: 11px 16px;
  text-decoration: none;

  /* Default colors */
  color: black;
  background: @bg-colour; /* unselected background */

  /* Only round the top corners */
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.tabs .active {
  /* Highest, active tab is on top */
  z-index: 3;
}
.tabs .active a {
  /* Colors when tab is active */
  background: @menu-bg-colour;
  color: @bg-colour;
}
.tabs li:before,
.tabs li:after,
.tabs li a:before,
.tabs li a:after {
  /* All pseudo elements are
     abs. positioned and on bottom */
  position: absolute;
  bottom: 0;
}
/* Only the first, last, and active
   tabs need pseudo elements at all */
.tabs li:after,
.tabs li a:after,
.tabs li:before,
.tabs li a:before,
.tabs:after,
.tabs:before,
.tabs a:after,
.tabs a:before {
  content: '';
}
.tabs .active:before,
.tabs .active:after {
  background: @menu-bg-colour;

  /* Squares below circles */
  z-index: 1;
}
/* Squares */
.tabs li:before,
.tabs li:after {
  background: @bg-colour; /* unselected background */
  width: 10px;
  height: 10px;
}
.tabs li:before {
  left: -10px;
}
.tabs li:after {
  right: -10px;
}
/* Circles */
.tabs li a:after,
.tabs li a:before {
  width: 20px;
  height: 20px;
  /* Circles are circular */
  border-radius: 10px;
  background: @bg-colour;

  /* Circles over squares */
  z-index: 2;
}
.tabs a:after,
.tabs a:before {
  background: @bg-colour;
}
/* First and last tabs have different
   outside color needs */
.tabs a:before,
.tabs a:after {
  background: @bg-colour;
}
.tabs li a:before {
  left: -20px;
}
.tabs li a:after {
  right: -20px;
}

.sections {
  position: absolute;
  width: 100%;
  margin: 0px;
  padding: 0px 10px 2px;
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  background-color: @menu-bg-colour;

  li {
    display: flex;
    text-align: center;
    flex: 1 0 auto;
    justify-content: center;

    a {
      color: @bg-colour;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
      padding: 11px 5px;
      user-select: none;

      &:hover {
        border-bottom: 5px solid @bg-colour;
        padding-bottom: 6px;
      }
    }

    &.active {
      a {
        &:extend(.sections li a:hover);
      }
    }
  }
}

.dropdown {
  display: flex;
  background-color: @menu-bg-colour;
  color: @bg-colour;
  font-weight: 600;
  font-size: 16px;
  width: 100%;

  > div {
    display: flex;
    flex-wrap: wrap;
    padding: 0 8px;
    align-items: center;
    cursor: pointer;
    user-select: none;

    // &:hover,
    &.active {
      > div {
        display: block;
        z-index: 100;
      }
    }

    > p {
      margin: 10px 9px 9px 0;
    }

    > div {
      display: none;
      flex-basis: 100%;
      height: 0;

      > ul {
        display: inline-block;
        cursor: initial;
        box-shadow: rgba(0, 0, 0, 0.15) 0 0.5px 10px 0px;
        border-radius: 0 0 3px 3px;
        background-color: @bg-colour;
        padding: 0;
        margin: 0;
        list-style: none;

        > li {
          font-size: 16px;
          font-weight: normal;

          &.active {
            a {
              background-color: rgba(0, 0, 0, 0.12);
            }
          }

          > a {
            display: block;
            padding: 15px 48px 15px 16px;
            color: rgba(0, 0, 0, 0.87);

            &:hover {
              &:extend(.dropdown > div > div > ul > li.active a);
            }
          }
        }
      }
    }
  }
}

.desktopTopSection {
  display: flex;
  justify-content: space-between;
  background-color: @bg-colour;
  align-items: center;
  max-width: 100vw;
  overflow-x: hidden;

  > .logo {
    flex: 1 1 10%;
    padding: 11px 0px 13px 15px;
  }

  > .empty {
    display: flex;
    flex: 1 1 10%;
    justify-content: flex-end;
    align-self: stretch;
    align-items: center;
  }
}
