.header {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 11px;
  align-items: center;
  justify-content: space-between;
  padding: 11px;
}
.title {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.02px;
  text-align: center;
  margin: 0px;
}
.action {
  background-color: #007aff40;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2px;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 17px;
  color: #007aff;
  font-weight: 600;
  letter-spacing: 0.2px;
  padding: 11px;
  width: fit-content;
  height: fit-content;
}
.accessory {
  display: grid;
}
.dismiss {
  width: 28px;
  height: 28px;
  border: none;
  outline: none;
  border-radius: 50%;
  background-color: #4a4a4a;
  display: grid;
  align-items: center;
  justify-content: center;
  justify-self: end;
  margin-right: 4px;
}
