.container {
  background-color: transparent;
  position: sticky;
  top: 3.5vh;
  align-self: center;
  z-index: 4;
  box-shadow: 0 0.5px 0.125rem 0 rgba(0, 0, 0, 0.13);
}
.container svg {
  width: 12rem;
  height: 3rem;
}
