.facesFeedbackComponent {
  border-bottom: 1px solid #979797;
  padding: 0 8px 8px 8px;
  margin-bottom: 8px;
  text-align: center;
}
.facesFeedbackComponent p {
  font-weight: 600;
}
.facesFeedbackComponent .validationError {
  color: red;
  margin: 4px 0;
  text-align: center;
}
@media screen and (max-width: 772px) {
  .facesFeedbackComponent {
    text-align: left;
  }
}
.facesWrapper {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  max-width: 320px;
  margin: 0 auto;
}
.face {
  cursor: pointer;
}
.feedbackOptions {
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
}
.feedbackOptions p {
  margin: 8px 0 4px 0;
}
.feedbackOptions select {
  max-width: 300px;
  align-self: center;
  margin: 4px 0 4px 0;
}
