.cardContainer {
  box-shadow: 0px 0.5px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  background-color: white;
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.contentContainer {
  width: 360px;
  min-height: 250px;
  max-height: 90vh;
  padding: 15px 10px;
  :global(.kiosk-web) & {
    width: 720px;
    min-height: 500px;
    max-height: 180vh;
  }
}

.closeButton {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  top: 7px;
  right: 6px;
  position: absolute;
  cursor: pointer;
  background-color: #4a4a4a;
  :global(.kiosk-web) & {
    width: 44px;
    height: 44px;
  }

  > div svg {
    width: 21px;
    height: 21px;

    :global(.kiosk-web) & {
      width: 42px;
      height: 42px;
    }
  }
}

.contentBanner {
  margin: -15px -10px 10px -10px;
}
.contentBanner > img {
  width: 100%;
}

.contentHeader {
  margin: 0 0 10px 0;
  font-size: 20px;
  :global(.kiosk-web) & {
    font-size: 40px;
  }
}

.contentBody {
  text-align: left;
  margin: 10px 0;
}

.contentFooter {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 10px -3px 0;
}
.contentFooter > img {
  flex-grow: 1;
  width: 50%;
  padding: 5px;
  align-self: center;
}
