.heightMixin(@height) {
  height: @height;
}

.widthMixin(@width) {
  width: @width;
}

.minWidthMixin(@minWidth) {
  min-width: @minWidth;
}

.fontSizeMixin(@fontSize) {
  font-size: @fontSize;
}

.lineHeightMixin(@lineHeight) {
  line-height: @lineHeight;
}

.maxHeightMixin(@maxHeight) {
  max-height: @maxHeight;
}

.cardMixin() {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 4px;

  @supports (display: grid) {
    margin: 0px;
  }
}

.cardContent {
  padding: 10px 10px 0 10px;
}

.imageMixin() {
  width: 100%;
  cursor: pointer;
  object-fit: cover;
}

.viewButtonMixin() {
  position: absolute;
  cursor: pointer;
  right: 8px;
  top: 11px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.4);
  font-weight: bold;
  color: #fff;
  border: none;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subtitleMixin() {
  padding-top: 6px;
  font-weight: 300;
  margin: 0px;
}

@media screen and (max-width: 365px) {
  .card {
    margin: 4px 2px;

    @supports (display: grid) {
      margin: 0px;
    }
  }
}

.descriptionMixin() {
  overflow-y: hidden;
  margin: 0;
  padding-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.titleMixin() {
  overflow-y: hidden;
  margin: 0;
  margin-top: 0;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.imageContainer {
  position: relative;
}

.card {
  .cardMixin();
  .widthMixin(174px);

  :global(.kiosk-web) & {
    .widthMixin(322px);

    &.leftNavMenu {
      .widthMixin(274px);
    }
  }
}

.image {
  .imageMixin();
  .heightMixin(150px);

  :global(.kiosk-web) & {
    .heightMixin(264px);
  }
  :global(.kiosk-web) .leftNavMenu & {
    .heightMixin(230px);
  }
}

.viewButton {
  .viewButtonMixin();
  .minWidthMixin(50px);
  .heightMixin(34px);
  .fontSizeMixin(16px);

  :global(.kiosk-web) & {
    .minWidthMixin(88px);
    .heightMixin(60px);
    .fontSizeMixin(28px);
  }
}

.subtitle {
  .subtitleMixin();
  .fontSizeMixin(14px);

  :global(.kiosk-web) & {
    .fontSizeMixin(25px);
  }
}

.description {
  .descriptionMixin();
  .lineHeightMixin(17px);
  .fontSizeMixin(12px);
  .maxHeightMixin(58px);

  :global(.kiosk-web) & {
    .lineHeightMixin(30px);
    .fontSizeMixin(21px);
    .maxHeightMixin(102px);
  }
}

.title {
  .titleMixin();
  .lineHeightMixin(17px);
  .fontSizeMixin(15px);
  .maxHeightMixin(35px);

  :global(.kiosk-web) & {
    .lineHeightMixin(30px);
    .fontSizeMixin(26px);
    .maxHeightMixin(62px);
  }
}
