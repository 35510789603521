.orderEta {
  color: rgb(#1fc36a);
  text-align: center;
  margin: 0 4px 4px 4px;
  border: 6px solid rgb(#1fc36a);
  border-radius: 10px;

  p {
    font-size: 20px;
    margin: 8px;
  }

  span {
    padding: 8px;
    display: block;
  }
}
