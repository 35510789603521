.text {
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  margin: 24px 12px;
  justify-content: center;
}
:global(.kiosk-web) .text {
  font-size: 1.2rem;
  margin: 48px 24px;
}
.wrapper {
  padding: 2vh 0.2vw 0.2vh 0.2vw;
}
:global(.kiosk-web) .wrapper {
  min-width: 73vw;
  padding: 3vh 1vw 1vh 1vw;
}
.icon {
  text-align: center;
  margin-bottom: 1vh;
  justify-content: center;
  width: 75;
  height: 75;
  height: auto;
  margin: 0 auto 0 auto;
}
:global(.kiosk-web) .icon {
  margin-bottom: 0.5vh;
  width: 100;
  height: 100;
}
.actionButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1vh;
  text-align: center;
  margin-top: 1rem;
}
:global(.kiosk-web) .title {
  font-size: 1.7rem;
  font-weight: 700;
}
