.customerRequiresChangeCheckbox {
  > div {
    padding: 10px;

    &:last-child {
      font-size: 14px;
      font-weight: 600;
    }
  }
}
