.card {
  width: 100%;
}

.cardTitle {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.02px;
  min-height: 26px;
  margin: 0;
  padding: 14px 8px 0 8px;
}

.cardTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.childrenNoPaddingWidth {
  padding: 8px 0 8px 0;
}

.children {
  padding: 8px 8px 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.triggerContainer {
  padding: 0 8px 0 8px;
}
