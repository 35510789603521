.wrapper {
  display: flex;
  flex-direction: column;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 4;
}
.mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 48px;
  padding: 0 8px;
}
.mobile > .logo {
  flex: 1;
  text-align: center;
}
.mobile .back {
  display: flex;
  align-items: center;
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 600;
  flex: 1;
  cursor: pointer;
}
.mobile .back > span {
  margin-left: 4px;
}
.mobile > .empty {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex: 1;
}
.mobile > .resetButton {
  display: flex;
  flex: 1;
  margin-right: 2%;
  justify-content: right;
}
.group:before,
.group:after {
  content: '';
  display: table;
}
.group:after {
  clear: both;
}
.group {
  zoom: 1;
  padding: 0;
}
.tabs {
  list-style: none;
  margin: 0;
  align-self: flex-end;
}
.tabs li {
  /* Makes a horizontal row */
  float: left;
  /* So the psueudo elements can be
     abs. positioned inside */
  position: relative;
  margin-right: 10px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 16px;
}
.tabs a {
  /* Make them block level
     and only as wide as they need */
  float: left;
  padding: 11px 16px;
  text-decoration: none;
  /* Default colors */
  color: black;
  background: white;
  /* unselected background */
  /* Only round the top corners */
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.tabs .active {
  /* Highest, active tab is on top */
  z-index: 3;
}
.tabs .active a {
  /* Colors when tab is active */
  background: #4a4a4a;
  color: white;
}
.tabs li:before,
.tabs li:after,
.tabs li a:before,
.tabs li a:after {
  /* All pseudo elements are
     abs. positioned and on bottom */
  position: absolute;
  bottom: 0;
}
/* Only the first, last, and active
   tabs need pseudo elements at all */
.tabs li:after,
.tabs li a:after,
.tabs li:before,
.tabs li a:before,
.tabs:after,
.tabs:before,
.tabs a:after,
.tabs a:before {
  content: '';
}
.tabs .active:before,
.tabs .active:after {
  background: #4a4a4a;
  /* Squares below circles */
  z-index: 1;
}
/* Squares */
.tabs li:before,
.tabs li:after {
  background: white;
  /* unselected background */
  width: 10px;
  height: 10px;
}
.tabs li:before {
  left: -10px;
}
.tabs li:after {
  right: -10px;
}
/* Circles */
.tabs li a:after,
.tabs li a:before {
  width: 20px;
  height: 20px;
  /* Circles are circular */
  border-radius: 10px;
  background: white;
  /* Circles over squares */
  z-index: 2;
}
.tabs a:after,
.tabs a:before {
  background: white;
}
/* First and last tabs have different
   outside color needs */
.tabs a:before,
.tabs a:after {
  background: white;
}
.tabs li a:before {
  left: -20px;
}
.tabs li a:after {
  right: -20px;
}
.sections {
  position: absolute;
  width: 100%;
  margin: 0px;
  padding: 0px 10px 2px;
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  background-color: #4a4a4a;
}
.sections li {
  display: flex;
  text-align: center;
  flex: 1 0 auto;
  justify-content: center;
}
.sections li a {
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  padding: 11px 5px;
  user-select: none;
}
.sections li a:hover,
.sections li.active a {
  border-bottom: 5px solid white;
  padding-bottom: 6px;
}
.dropdown {
  display: flex;
  background-color: #4a4a4a;
  color: white;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
}
.dropdown > div {
  display: flex;
  flex-wrap: wrap;
  padding: 0 8px;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.dropdown > div.active > div {
  display: block;
  z-index: 100;
}
.dropdown > div > p {
  margin: 10px 9px 9px 0;
}
.dropdown > div > div {
  display: none;
  flex-basis: 100%;
  height: 0;
}
.dropdown > div > div > ul {
  display: inline-block;
  cursor: initial;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0.5px 10px 0px;
  border-radius: 0 0 3px 3px;
  background-color: white;
  padding: 0;
  margin: 0;
  list-style: none;
}
.dropdown > div > div > ul > li {
  font-size: 16px;
  font-weight: normal;
}
.dropdown > div > div > ul > li.active a,
.dropdown > div > div > ul > li > a:hover {
  background-color: rgba(0, 0, 0, 0.12);
}
.dropdown > div > div > ul > li > a {
  display: block;
  padding: 15px 48px 15px 16px;
  color: rgba(0, 0, 0, 0.87);
}
.desktopTopSection {
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  max-width: 100vw;
  overflow-x: hidden;
}
.desktopTopSection > .logo {
  flex: 1 1 10%;
  padding: 11px 0px 13px 15px;
}
.desktopTopSection > .empty {
  display: flex;
  flex: 1 1 10%;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
}
