.selectListWrapper {
  padding: 8.3px 0px;
  width: 100%;
  position: relative;
}

.cardWrapper {
  display: flex;
  border-radius: 15px;
  height: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}

.actionButtonWrapper {
  padding: 0 7.5px 8.3px 7.5px;
}

.iconWrapper {
  text-align: center;
  padding: 28px 0;
}

.image {
  width: 100%;
  height: 149px;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.title {
  margin: 0.5rem 0rem;
}
