@import '../../../hooks-and-hocs/utils/breakpoints/breakpoints.less';
@import '../../../hooks-and-hocs/theme/theme-data/standard-theme.less';

.container {
  padding: 10px;
  border-radius: 19px 19px 0px 0px;
  display: flex;
  &.longTextContainer:not(:global(.kiosk-web)) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  @media @mobile {
    background-color: #fff;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.203371);
  }
  :global(.kiosk-web) & {
    background-color: #fff;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.203371);
    padding: 20px;
    flex-direction: row !important;
    margin-bottom: 0 !important;

    & > *:not(:last-child) {
      margin-right: 10px !important;
    }
  }
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 14px;
  white-space: nowrap;
  height: 100%;
  align-items: center;
  font-weight: 600;
  :global(.kiosk-web) & > div {
    font-size: 30px;
  }
}

.placeOrderButton,
.skipButton {
  height: 38px;
  flex: 1;
  width: 150px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  &.longText:not(:global(.kiosk-web)) {
    margin-right: 0;
    margin-bottom: 8px;
    width: 98%;

    @media screen and (min-width: 425px) {
      width: 400px;
    }
  }

  &.skipButton {
    background-color: @standardColors[action-background-blue] !important;
    color: @standardColors[action-text-blue];
  }

  &.placeOrderContainer {
    background-color: @standardColors[action-text-blue] !important;
    color: @standardColors[button-text-default] !important;
  }

  &.disabled {
    background-color: @standardColors[action-background-blue];
    color: @standardColors[button-text-disabled];
  }

  :global(.kiosk-web) & {
    height: 98px !important;
    border-radius: 16px !important;
  }
}
