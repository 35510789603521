.content {
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: relative;
  height: 100%;
  align-items: center;
}
.icon {
  position: absolute;
  left: 0;
}
.text {
  font-size: 17px;
  color: #ffffff;
  font-weight: 600;
}
:global(.kiosk-web) .text {
  font-size: 27px;
}
.splitContent {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.button {
  height: 54px;
  width: 100%;
}
:global(.kiosk-web) .button {
  height: 80px;
}
