.card {
  width: 400px;
  max-width: 95vw;
  max-height: 90vh;
  box-shadow: 0px 0.5px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  background-color: white;
  z-index: 1;
  overflow: hidden;
}
:global(.kiosk-web) .card {
  width: 600px;
  border-radius: 32px;
}
.layout {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto;
  grid-auto-rows: auto;
  gap: 8px;
  padding: 12px;
}
.layout .title {
  font-size: 20px;
  margin: initial;
  grid-row: 1;
  grid-column: 1;
}
:global(.kiosk-web) .layout .title {
  font-size: 35px;
}
.layout .close {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  cursor: pointer;
  background-color: #4a4a4a;
  grid-row: 1;
  grid-column: 2;
}
.layout .body {
  text-align: left;
  grid-row: 2;
  grid-column: span 2;
}
:global(.kiosk-web) .layout .body {
  font-size: 25px;
}
.image {
  width: 100%;
  height: min-content;
}
.image img {
  display: block;
  width: 100%;
}
