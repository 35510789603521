.nutritionalContainer {
  padding-bottom: 8px;
}

.buttonContainer {
  margin: 3px;

  > button {
    width: 100%;

    :global(.kiosk-web) & {
      height: 70px;
      border-radius: 16px !important;
    }

    > div {
      font-size: 16px !important;

      :global(.kiosk-web) & {
        font-size: 26px !important;
      }
    }
  }
}

.button {
  font-weight: bold;
  width: '100%';
}
