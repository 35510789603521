.close {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  top: 5px;
  right: 4px;
  z-index: 3;
  position: absolute;
  background-color: rgba(22, 22, 22, 0.7);
  backdrop-filter: blur(10px);
  cursor: pointer;
}
.back {
  cursor: pointer;
  top: 5px;
  z-index: 3;
  width: 74px;
  height: 32px;
  border-radius: 50px;
  left: 4px;
  color: white;
  position: fixed;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.5px 2px 0 rgba(0, 0, 0, 0.13);
  background-color: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(10px);
  user-select: none;
}
.back svg {
  width: 14px;
  height: 12px;
}
:global(.kiosk-web) .back {
  width: 150px;
  height: 60px;
  font-size: 30px;
}
:global(.kiosk-web) .back svg {
  width: 30px;
  height: 20px;
}
.backText {
  margin-left: 4px;
}
