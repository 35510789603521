@import '../../../hooks-and-hocs/theme/theme-data/standard-theme.less';

.empty {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex: 1;

  :global(.kiosk-web) & {
    display: none;
  }
}

.container {
  background-color: @standardColors[default-background];
  display: flex;
  flex: 0 0 auto;
  position: sticky;
  top: 0;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
  box-shadow: @shadow[light];
  height: 88px;
  padding: 0 27px 0 18px;

  :global(.kiosk-web) & {
    svg {
      width: 160px;
      height: 55px;
    }
  }
}

@media only screen and (max-width: 772px) {
  .container {
    height: 48px;
    padding: 0 8px 0 8px;
  }
}
