.cardContainer {
  box-shadow: 0px 0.5px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  background-color: white;
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.contentContainer {
  width: 400px;
  max-width: 95vw;
  min-height: 250px;
  max-height: 90vh;
  padding: 15px 10px;
}

.closeButton {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  top: 7px;
  right: 6px;
  position: absolute;
  cursor: pointer;
  background-color: #4a4a4a;
}

.contentBannerImage {
  margin: -15px -10px 10px;

  img {
    width: 100%;
  }
}

.contentHeader {
  margin: 0 0 10px 0;
  font-size: 20px;
}

.contentSubheader {
  font-weight: bold;
  margin: 10px 0 0;
}

.contentBody {
  text-align: left;
  margin-top: 0;
}

.contentFooter {
  display: flex;
  gap: 10px;

  button {
    width: 100%;
    font-weight: bold;

    @media screen and (max-width: 420px) {
      > div:first-child {
        font-size: 14px !important;
      }
    }
  }
}
