.wrapper {
  width: 350px;
  min-height: 90px;
  position: relative;
  border-radius: 15px;
  padding: 11px;
  background: linear-gradient(to right, rgb(212, 202, 192), rgb(210, 201, 190), rgb(191, 187, 183));
}

.closeButton {
  z-index: 2;
  cursor: pointer;
}

.arrow {
  position: absolute;
  width: 30px;
  height: 30px;
  top: -5px;
  right: 3px;
  border-radius: 4px 13px 0px 0px;
  transform: rotate(51deg);
  background: rgb(191, 187, 183);
  z-index: 1;
}
.titleContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.title {
  color: #000000;
  font-size: 17px;
  font-weight: 700;
}
.subTitle {
  color: #6f6f6f;
  font-size: 15px;
  font-weight: 500;
  overflow-y: auto;
  overflow-x: hidden;
}
