.container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.inner {
  display: flex;
  align-items: center;
}
.redeemButton {
  background-color: transparent;
  border: 0;
  color: #007aff;
  cursor: pointer;
  font-family: inherit;
  font-size: 15px;
  font-weight: 600;
  outline: 0;
  padding: 10px 5px 10px 10px;
  text-transform: uppercase;
}
.loading {
  cursor: auto;
}
.iconWrapper {
  margin-right: 10px;
}
.availablePizzaLabel {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}
