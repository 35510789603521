.switchLayoutDialogWrapper {
  position: fixed;
  transform: rotate(-180deg);
  right: 4.45rem;
  bottom: 7.57rem;
  z-index: 3;
  display: block;
  padding: 0 !important;
  background-color: white;
}
.dialog {
  border: none !important;
  border-radius: 0.25rem;
  padding: 0.15rem;
  box-shadow: 0px 0.5px 10px 0px rgba(0, 0, 0, 0.25);
  transform: rotate(180deg);
}
.container {
  width: 9.4rem;
  height: 2.3439rem;
  gap: 0.3129rem;
  border-radius: 0.4688rem;
  border: 0.07789rem solid rgba(200, 199, 204, 0.4);
  display: flex;
  align-items: center;
  color: black;
  position: relative;
  background-color: #ffffff;
  margin: 0.5rem 0.15625rem;
  padding: 0.25rem;
}
.container.selectedLayout {
  border: 0.07789rem solid #007aff;
}
.tick {
  position: absolute;
  right: 0.35rem;
  color: black;
}
.flag {
  width: 1.57rem;
  height: 1.1rem;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  border: 1px solid #c8c7cc;
}
.changeLanguage {
  margin-bottom: 0.3125rem;
  color: black;
  font-size: large;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.15625rem;
}
.languageButton {
  display: flex;
  justify-content: center;
  margin-bottom: 0.3125rem;
  font-size: large;
  gap: 0.3125rem;
}
