.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  gap: 0.5rem;
}
.row .container {
  height: 2.25rem !important;
  background: transparent !important;
  padding-left: 0 !important;
}
.row input {
  border-radius: 0.625rem !important;
  width: 100%;
}
.inputError {
  border-width: 1px !important;
  border-style: solid !important;
  color: #e21836 !important;
  border-color: #e21836 !important;
  background-color: #fbdee3 !important;
  outline: none !important;
}
.errorContainer {
  padding-top: 0.125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
.error {
  font-size: 0.875rem;
  text-transform: uppercase;
  padding: 0.125rem 0px;
  text-align: center;
  font-weight: 400;
}
.button {
  min-height: 2.25rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  background-color: #007aaf !important;
  border-radius: 0.5rem !important;
}
