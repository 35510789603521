.wrapperInner {
  padding: 10px 10px 0px 10px;
}

.wrapper {
  border-radius: 15px;
  margin-top: 20px;
  padding: 0px 0px 10px 0;
  background-color: white;
  box-shadow: 0px 0.5px 10px 0px rgba(0, 0, 0, 0.15);

  .maxHeight {
    height: calc(80vh - 10px);
    @media screen and (max-width: 772px) {
      height: calc(100vh - 10px);
    }
  }
}

iframe {
  border: none;
}

h2 {
  margin-top: 5px;
}
