.slider {
  div[class*='slider-wrapper'] {
    cursor: pointer;
  }
}

.multiItem {
  div[class*='slider-wrapper'] {
    padding-bottom: 40px;
  }

  ul[class='control-dots'] {
    bottom: 0px;

    li[class='dot'],
    li[class*='selected'] {
      background: #000;
      opacity: 0.3;
      width: 8px;
      height: 8px;
      box-shadow: none;
      margin: 6px;
    }

    li[class*='selected'] {
      opacity: 1;
    }
  }
}
