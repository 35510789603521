.productEditorContent {
  padding: 8px;
  width: 100%;
  z-index: 2;
  padding-bottom: 178px;

  section {
    position: relative;
    margin-bottom: 8px;
  }

  :global(.kiosk-web) & {
    margin-bottom: 100px;
    padding-bottom: 25px;
  }

  @media screen and (max-width: 954px) {
    top: 203px;
  }

  @media screen and (max-width: 772px) {
    margin-bottom: 65px;
    padding-bottom: 15px;
  }
}
