.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 391px;
  min-height: 620px;
  border-radius: 15px;
  position: relative;
}

.backgroundImg {
  min-height: 620px;
  max-width: 391px;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
}

.buttonsContainer {
  position: absolute;
  bottom: 5%;
  width: 90%;
  max-height: 40px;
  margin: 0px 5%;
  display: flex;
  gap: 5%;
  justify-content: space-evenly;
}

.buttonBgImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
