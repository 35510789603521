.wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 360px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5px 10px 0px;
  margin: 5px 0 15px 0;
}
.icon {
  border-right: solid 0.5px rgba(151, 151, 151, 0.2);
  padding: 15px;
  display: grid;
  place-items: center;
}
.content {
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 15px 0 15px 15px;
}
.content:has(.message) {
  justify-items: start;
}
.heading {
  font-size: 1.2em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.12px;
}
.message {
  font-size: 12px;
}
.closeButtonWrapper {
  padding: 10px 5px 0 0;
}
