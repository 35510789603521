.bellContainer {
  position: relative;
  right: 15px;
  bottom: 19px;

  svg {
    width: 10px !important;
    height: 10px !important;

    path {
      fill: #fff;
    }
  }
}

.countContainer {
  display: contents;
}
