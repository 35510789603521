.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  width: 100%;
}
.title {
  font-size: max(min(10vh, 11.5vw), 53px);
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.2vw;
  margin-top: max(min(1vh, 2vw), 5px);
  margin-bottom: max(min(2vh, 5vw), 10px);
  text-align: center;
}
.image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -999999999;
}
.image img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.languageSelector {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1.5);
  margin-bottom: 2rem;
  align-self: center;
}
