.container,
.containerOutline {
  margin-inline: 0.25rem;
  border-radius: 0.75rem;
  margin-bottom: 0.75rem;
}
.container {
  border: 0.0625rem solid #e3e2e4;
}
.containerOutline {
  border: 0.0625rem solid #007aff;
}
.innerContainer {
  display: flex;
  flex-direction: row;
}
.checkboxContainer {
  position: absolute;
  right: 0.9375rem;
  margin-top: 0.5rem;
}
.checkboxWrapper {
  display: flex;
  background-color: greenyellow;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.imageContainer {
  display: flex;
  position: relative;
  justify-content: center;
}
.image {
  margin-top: 0.75rem;
  width: 13.75rem;
  height: 11.88rem;
  object-fit: cover;
  border-radius: 0.75rem;
  margin: 0.625rem;
  width: 7.5rem;
  height: 5rem;
}
.title {
  margin-top: 0;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
  font-size: 0.875rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.price {
  font-size: 0.875rem;
  color: #6e6e6e;
  margin-bottom: 0;
  font-size: 0.75rem;
  font-weight: 600;
  margin-top: 0.25rem;
}
.textContainer {
  padding-right: 1.875rem;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.legends {
  display: flex;
  justify-content: center;
  justify-content: flex-end;
  position: absolute;
  left: 1rem;
  bottom: 0.125rem;
  left: 0.75rem;
  bottom: 0.625rem;
}
.legends img {
  width: 1.25rem;
}
