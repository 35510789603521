.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px;
  gap: 10px;
}
.container .messageContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #4a4a4a;
  gap: 10px;
}
.container .messageContainer .messageBody {
  display: flex;
  flex-direction: column;
}
.container .messageContainer .messageBody p {
  margin: 0;
}
.container .messageContainer .messageBody .title {
  text-transform: uppercase;
  font-weight: bold;
}
