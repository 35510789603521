.container {
  background-size: cover;
  cursor: pointer;
  height: 5rem;
  border-radius: 0.625rem;
  margin-top: 0.5rem;
  position: relative;
  overflow: hidden;
  user-select: none;
  -webkit-user-select: none;
}
.container > * {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.45);
}
.innerContainer,
.innerContainerWithoutTitle {
  align-items: center;
  display: flex;
  height: 100%;
  padding: 0.625rem 0.875rem 0.625rem 0.625rem;
}
.innerContainer {
  justify-content: space-between;
}
.innerContainerWithoutTitle {
  justify-content: flex-end;
}
.innerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lightOverlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.darkOverlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.36) 40%);
}
.title {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
}
:global(.kiosk-web) .title {
  font-size: 40px;
}
.iconContainer,
.title {
  z-index: 1;
}
.image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 0;
}
