.legendWrapper {
  pointer-events: none;
}

.legendContainer {
  padding: 2px;
}

.legendCode {
  font-size: 15px;
}

.itemCode {
  :global(.kiosk-web) & {
    font-size: 18px;
  }
}

.legendImage {
  width: 29px;
}
